import React, { Component } from "react";
import MenuIcon from '@material-ui/icons/Menu';
import TextField from '@material-ui/core/TextField';
import Autocomplete from '@material-ui/lab/Autocomplete';
import Typography from '@material-ui/core/Typography';
import ChevronLeftIcon from '@material-ui/icons/ChevronLeft';
import Accordion from '@material-ui/core/Accordion';
import AccordionSummary from '@material-ui/core/AccordionSummary';
import AccordionDetails from '@material-ui/core/AccordionDetails';
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import Checkbox from '@material-ui/core/Checkbox';
import Button from '@material-ui/core/Button';
import { DataGrid } from '@material-ui/data-grid';
import "./Search.css";

Date.prototype.toDateInputValue = (function(moAgo) {
    const local = new Date(this);
    if (moAgo) {
      local.setMonth(local.getMonth() - moAgo);
    }
    local.setMinutes(this.getMinutes() - this.getTimezoneOffset());
    return local.toJSON().slice(0,10);
});
const PAST_DATE = new Date().toDateInputValue(3);
const TODAY = new Date().toDateInputValue();

// green:  #25b03d
// yellow: #e1e10a
// orange: #e33a10
// black:  #000202
// white:  #ebebeb
const icawOptions = [
  { font: '#25b03d', bg: '#000202', title: 'FCS ALIGN TNS' },
  { font: '#000202', bg: '#e1e10a', title: 'FCS CG DEGD' },
  { font: '#000202', bg: '#e1e10a', title: 'FCS CH FAIL A' },
  { font: '#000202', bg: '#e1e10a', title: 'FCS CH FAIL B' },
  { font: '#000202', bg: '#e1e10a', title: 'FCS CH FAIL C' },
  { font: '#000202', bg: '#e1e10a', title: 'FCS CH HOT A' },
  { font: '#000202', bg: '#e1e10a', title: 'FCS CH HOT B' },
  { font: '#000202', bg: '#e1e10a', title: 'FCS CH HOT C' },
  { font: '#ebebeb', bg: '#e33a10', title: 'FCS DATA FAIL' },
  { font: '#000202', bg: '#e1e10a', title: 'FCS DEGD' },
  { font: '#000202', bg: '#e1e10a', title: 'FCS EHA HOT' }
];

const icawClasses = {
  'FCS ALIGN TNS': 'fcsA', 
  'FCS CG DEGD': 'fcsB', 
  'FCS CH FAIL A': 'fcsC', 
  'FCS CH FAIL B': 'fcsD', 
  'FCS CH FAIL C': 'fcsE', 
  'FCS CH HOT A': 'fcsF', 
  'FCS CH HOT B': 'fcsG', 
  'FCS CH HOT C': 'fcsH', 
  'FCS DATA FAIL': 'fcsI', 
  'FCS DEGD': 'fcsJ', 
  'FCS EHA HOT': 'fcsK', 
};

const missionTypeOptions = [
  { title: 'A/A' },
  { title: 'A/S' },
  { title: 'SEAD' },
  { title: 'Other' }
]


const squadronOptions = [
  { title: 'VFA-125' },
  { title: 'VFA-147' },
  { title: 'VMFA-314' },
  { title: 'VX-9' }
]

const aircraftOptions = [
  { title: '410' },
  { title: '411' },
  { title: '412' },
  { title: '413' },
  { title: '414' },
  { title: '415' },
  { title: '416' },
  { title: '417' },
  { title: '418' },
  { title: '419' },
  { title: '420' },
  { title: '421' },
  { title: '422' },
  { title: '423' },
  { title: '424' },
  { title: '425' },
  { title: '426' },
  { title: '427' },
  { title: '428' }
]

const formatDate = (dateStr) =>  {
  let date = new Date(dateStr);
  let year = date.getFullYear();
  let month = (1 + date.getMonth()).toString();
  month = month.length > 1 ? month : '0' + month;
  let day = date.getDate().toString();
  day = day.length > 1 ? day : '0' + day;
  
  return month + '/' + day + '/' + year;
}

const initial_columns = [
  { field: 'date', headerName: 'Date', width: 150, valueGetter: (params) => formatDate(params.getValue('date')) },
  { field: 'squadron', headerName: 'Squadron', width: 130 },
  { field: 'aircraft', headerName: 'Aircraft', width: 130 }
];

const otherDegsFails =  ["radar","comms","nav","iff","intgtr","eots","l16","madl","sms","cm"];
const codeLabels = {
  radar: "Radar",
  comms: "Comms",
  nav: "Nav",
  iff: "IFF",
  intgtr: "Interrogator",
  eots: "EOTS",
  l16: "L16",
  madl: "MADL",
  sms: "SMS",
  cm: "CM"
}

const colLabels = {
  radarDeg: "Radar Degrade",
  radarFail: "Radar Fail",
  commsDeg: "Comms Degrade",
  commsFail: "Comms Fail",
  navDeg: "Nav Degrade",
  navFail: "Nav Fail",
  iffDeg: "IFF Degrade",
  iffFail: "IFF Fail",
  intgtrDeg: "Interrogator Degrade",
  intgtrFail: "Interrogator Fail",
  eotsDeg: "EOTS Degrade",
  eotsFail: "EOTS Fail",
  l16Deg: "L16 Degrade",
  l16Fail: "L16 Fail",
  madlDeg: "MADL Degrade",
  madlFail: "MADL Fail",
  smsDeg: "SMS Degrade",
  smsFail: "SMS Fail",
  cmDeg: "CM Degrade",
  cmFail: "CM Fail",
  degradesAffectMission: "Degrades Affect Mission",
  issuesLedToAbort: "In Flight Mission Abort",
  postFlightVSBit: "Post Flight VS Bit No Go",
  coldIronRequired: "Cold Iron Required",
  wereSparesRequired: "Spares Required",
  wereSparesAvailable: "Spares Available"
}

const ewDegsFails = ["EW"];
const dasDegsFails = ["DAS","BA","BF","LS","RS","TA","TF"];
const aircraftQuestions = [1,2,3,4];
const aircraftQuestionsB = [0,1,2,3,4];
const qkeyLabels = {
  label_0: "None",
  label_1: "One",
  label_2: "Two",
  label_3: "Three",
  label_4: "Four",
}

const INITIAL_STATE = {
  flightTime: [0, 2400],
  hms: "any",
  hmr: "any",
  hma: "any",
  columns: initial_columns,
  tacticalMissionType: null,
  icaw: null,
  squadron: null,
  aircraft: null,
  startDate: PAST_DATE,
  endDate: TODAY,
  degEW: false,
  failEW: false,
  degFL: false,
  degFR: false,
  degAL: false,
  degAR: false,
  failFL: false,
  failFR: false,
  failAL: false,
  failAR: false,
  degDAS: false,
  failDAS: false,
  degBA: false,
  degBF: false,
  degLS: false,
  degRS: false,
  degTA: false,
  degTF: false,
  failBA: false,
  failBF: false,
  failLS: false,
  failRS: false,
  failTA: false,
  failTF: false,
  radarDeg: false,
  commsDeg: false,
  navDeg: false,
  iffDeg: false,
  intgtrDeg: false,
  eotsDeg: false,
  l16Deg: false,
  madlDeg: false,
  smsDeg: false,
  cmDeg: false,
  radarFail: false,
  commsFail: false,
  navFail: false,
  iffFail: false,
  intgtrFail: false,
  eotsFail: false,
  l16Fail: false,
  madlFail: false,
  smsFail: false,
  cmFail: false,
  degradesAffectMission: false,
  wereSparesRequired: false,
  wereSparesAvailable: false,
  flightLeadForEvent: false,
  issuesLedToAbort: false,
  postFlightVSBit: false,
  coldIronRequired: false
}

export default class Search extends Component {
  constructor(props) {
    super(props);

    let filteredData = this.props.pfdData;

    this.state = {
      ...INITIAL_STATE,
      menuIsOpen: true,
      filteredData
    };
  }

  componentDidUpdate() {
    // window.scrollTo(0, 0)
  }

  getEWCodes(params) {
    let codes = params.getValue('codes');
    let quad = codes["ew"]["quad"];
    return quad;
  }

  resetFilters() {
    let filteredData = this.props.pfdData;
    let menuIsOpen = true;
    this.setState({
      ...INITIAL_STATE,
      menuIsOpen,
      filteredData
    })
  }

  getDASCodes(params) {
    let codes = params.getValue('codes');
    let quad = codes["das"]["quad"];
    return quad;
  }

  getICAWS(params) {
    let icaws = params.getValue('extraDiscrepancies').map(disc => disc.icaw);
    return icaws;
  }

  createCol(key, header, width = 200) {
    let newCol = { field: key, headerName: header, width };
    const d = ['BA','BF','LS','RS','TA','TF'];

    if (Object.keys(colLabels).includes(key)) {
      newCol = {
        field: key,
        headerName: header,
        width,
        renderCell: (params) => (<div className="checkBoxIcon"></div>)
      };
    }

    if (key === "icaw") {
      newCol = {
        field: "icaw",
        headerName: header,
        width,
        valueGetter: this.getICAWS,
        renderCell: (params) => {
          let q = params.value;
          return (q.map((icaw,i) => (<span key={`icaw-${i}`} className={`icawValue ${icawClasses[icaw]}`}>{icaw}</span>))  )
        }
      };
    }

    if (key === "degDAS") {
      newCol = {
        field: "degDAS",
        headerName: header,
        width,
        valueGetter: this.getDASCodes,
        renderCell: (params) => {
          let q = params.value;
          // q = [1,1,0,0,2,2]
          let fq = q.map((v, i) => v === 1 ? d[i] : '' ).filter(v => v !== '');
          return (
            <div className="dasQuads">{fq.join(',')}</div>
          )
        }
      };
    }
    if (key === "failDAS") {
      newCol = {
        field: "failDAS",
        headerName: header,
        width,
        valueGetter: this.getDASCodes,
        renderCell: (params) => {
          let q = params.value;
          // q = [1,1,0,0,2,2]
          let fq = q.map((v, i) => v === 2 ? d[i] : '' ).filter(v => v !== '');
          return (
            <div className="dasQuads">{fq.join(',')}</div>
          )
        }
      };
    }
    if (key === "ewDeg") {
      newCol = {
        field: key,
        headerName: header,
        width,
        valueGetter: this.getEWCodes,
        renderCell: (params) => {
          let q = params.value;
          return (
            <div className="gridQuad">
              <div className={`quadSection deg ${q[0] === 1 ? 'fl' : ''}`}></div>
              <div className={`quadSection deg ${q[1] === 1 ? 'fr' : ''}`}></div>
              <div className={`quadSection deg ${q[2] === 1 ? 'al' : ''}`}></div>
              <div className={`quadSection deg ${q[3] === 1 ? 'ar' : ''}`}></div>
            </div>
          )
        }
      };
    }
    if (key === "ewFail") {
      newCol = {
        field: key,
        headerName: header,
        width,
        valueGetter: this.getEWCodes,
        renderCell: (params) => {
          let q = params.value;
          return (
            <div className="gridQuad">
              <div className={`quadSection fail ${q[0] === 2 ? 'fl' : ''}`}></div>
              <div className={`quadSection fail ${q[1] === 2 ? 'fr' : ''}`}></div>
              <div className={`quadSection fail ${q[2] === 2 ? 'al' : ''}`}></div>
              <div className={`quadSection fail ${q[3] === 2 ? 'ar' : ''}`}></div>
            </div>
          )
        }
      };
    }
    return newCol;
  }

  getColumns(key, header, width = 200) {
    let newCol = this.createCol(key, header, width);
    let columns = [...this.state.columns].filter((o) => (o.field !== key));
    columns.push(newCol);
    return columns;
  }

  removeCol(key) {
    let columns = [...this.state.columns].filter((o) => (o.field !== key));
    return columns;
  }

  minutesAreInRange(start, end, target) {
    return (start <= target && end >= target)
  }

  withinFlightTimeRange(records, range) {
    const startT = range[0];
    const endT = range[1];
    const start = (startT/100)*60;
    const end = (endT/100)*60;
    return records.filter(r => (this.minutesAreInRange(start, end, r.takeOffTime) && this.minutesAreInRange(start, end, r.landTime)) )
  }

  dateIsInRange(start, end, target) {
    const s = new Date(start);
    const e = new Date(end);
    const t = new Date(target);
    return (s <= t && e >= t);
  }

  withDegOrFail(records, codeKey, state) {
    if (!records) { return [] }
    if (codeKey === 'ew' || codeKey === 'das') {
      const num = state === 'deg' ? 1 : 2;
      return records.filter(r => r.codes[codeKey].quad.filter(q => q === num).length > 0 )
    }
    return records.filter(r => ((r.codes || {})[codeKey] || {})[state] )
  }

  doesEwOrDasHaveSpecificQuadDeg(records, codeKey, quad) {
    return records.filter(r => ((((r.codes || {})[codeKey] || {}).quad || [])[quad] === 1) )
  }

  doesEwOrDasHaveSpecificQuadFail(records, codeKey, quad) {
    return records.filter(r => ((((r.codes || {})[codeKey] || {}).quad || [])[quad] === 2) )
  }

  itemContainsText(records, itemKey, query) {
    return records.filter(r => (r[itemKey] || "").includes(query))
  }

  arrayContainsText(records, itemKey, array) {
    return records.filter(r => array.includes(r[itemKey]))
  }

  textMatch(records, itemKey, val) {
    return records.filter(r => r[itemKey] === val);
  }

  itemIsTrue(records, itemKey) {
    return records.filter(r => r[itemKey])
  }

  sparesAvailable(records) {
    return records.filter(r => (r.howManyRequired <= r.howManyAvailable))
  }

  itemEqVal(records, key, val) {
    return records.filter(r => r[key] === val)
  }

  itemIsFalse(records, itemKey) {
    return records.filter(r => !r[itemKey])
  }

  handleOpenDrawer(a) {
    this.setState({ menuIsOpen: true })
  }

  handleCloseDrawer(a) {
    this.setState({ menuIsOpen: false })
  }

  handleFlightTimeChange(event, newValue) {
    this.setState({ flightTime: newValue })
  }

  valueLabelFormat(value) {
    if (value < 1000) {
      return `${value.toString().substring(0,1)}:${value.toString().substring(1,3)}`;
    } else {
      return `${value.toString().substring(0,2)}:${value.toString().substring(2,4)}`;
    }
  }

  getSliderText(value) {
    return `${value}`;
  }

  handleAQChange(id, val) {
    let keys = {
      hmr: "howManyRequired",
      hma: "howManyAvailable",
      hms: "numberOfAircraft"
    }
    let labels = {
      hmr: "Aircraft Required",
      hma: "Aircraft Available",
      hms: "Aircraft Scheduled",
    }
    let columns = [];
    let prevVal = this.state[id];
    if (val === 'any' || (val === prevVal)) {
      columns = this.removeCol(keys[id]);
    } else {
      columns = this.getColumns(keys[id], labels[id], 180);
    }
    if (val === prevVal) {
      this.setState({ [id]: 'any', columns })
    } else {
      this.setState({ [id]: val, columns })
    }
  }

  handleTextSearchChange(id, event, selectedOptions) {
    let columns = [];
    if (id === "icaw" && !selectedOptions) {
      columns = this.removeCol("icaw");
      this.setState({ [id]: null, columns })
    } else if (!selectedOptions) {
      this.setState({ [id]: null })
    } else {
      let val = "";
      if (Array.isArray(selectedOptions)) {
        val = selectedOptions.map(o => o.title);
        this.setState({ [id]: val })
      } else {
        val = selectedOptions.title;
        if (id === "icaw") {
          columns = this.getColumns(id, "ICAW", 140);
          this.setState({ [id]: val, columns })
        } else if (id === "tacticalMissionType") {
          columns = this.getColumns(id, "Mission Type", 140);
          this.setState({ [id]: val, columns })
        } else {
          this.setState({ [id]: val })
        }
      }
    }
  }


  mu(columns) {
    let ddc = 0;
    let newCols = columns.filter((c) => { 
      if (c.field === "Das Degrades") {
        ddc++;
      }
      return (c.field !== "Das Degrades") || (ddc < 2)
    })
    return newCols;
  }

  handleDASCheckBoxChange(event, newValue) {
    const id = event.target.id;

    if (id === "degDAS") {
      let { degDAS, degBA, degBF, degLS, degRS, degTA, degTF } = this.state;
      let states = { degDAS, degBA, degBF, degLS, degRS, degTA, degTF };
      if (!newValue) {
        delete states[id];
      } else {
        states[id] = true;
      }
      let columns = [];
      let vals = Object.values(states).filter(v => v);
      if (vals.length > 0) {
        // DAS still selected
        columns = this.getColumns("degDAS", "Das Degrades", 140);
      } else {
        columns = this.removeCol("degDAS");
      }
      this.setState({
        [id]: newValue,
        columns,
        degBA: false,
        degBF: false,
        degLS: false,
        degRS: false,
        degTA: false,
        degTF: false,
      });
    } else if (id === "failDAS") {
      let { failDAS, failBA, failBF, failLS, failRS, failTA, failTF } = this.state;
      let states = { failDAS, failBA, failBF, failLS, failRS, failTA, failTF };
      if (!newValue) {
        delete states[id];
      } else {
        states[id] = true;
      }
      let columns = [];
      let vals = Object.values(states).filter(v => v);
      if (vals.length > 0) {
        // DAS still selected
        columns = this.getColumns("failDAS", "Das Failures", 140);
      } else {
        columns = this.removeCol("failDAS");
      }

      this.setState({
        [id]: newValue,
        columns,
        failBA: false,
        failBF: false,
        failLS: false,
        failRS: false,
        failTA: false,
        failTF: false,
      });
    } else if (id.includes('deg')) {
      let { degDAS, degBA, degBF, degLS, degRS, degTA, degTF } = this.state;
      let states = { degDAS, degBA, degBF, degLS, degRS, degTA, degTF };
      if (!newValue) {
        delete states[id];
      } else {
        states[id] = true;
      }
      let columns = [];
      let vals = Object.values(states).filter(v => v);
      if (vals.length > 0) {
        // DAS still selected
        columns = this.getColumns("degDAS", "Das Degrades", 140);
      } else {
        columns = this.removeCol("degDAS");
      }
      this.setState({
        [id]: newValue,
        columns,
        degDAS: false
      });
    } else if (id.includes('fail')) {
      let { failDAS, failBA, failBF, failLS, failRS, failTA, failTF } = this.state;
      let states = { failDAS, failBA, failBF, failLS, failRS, failTA, failTF };
      if (!newValue) {
        delete states[id];
      } else {
        states[id] = true;
      }
      let columns = [];
      let vals = Object.values(states).filter(v => v);
      if (vals.length > 0) {
        // DAS still selected
        columns = this.getColumns("failDAS", "Das Failures", 140);
      } else {
        columns = this.removeCol("failDAS");
      }
      this.setState({
        [id]: newValue,
        columns,
        failDAS: false
      });
    } else {
      this.setState({ [id]: newValue });
    }
  }

  handleEWDegChange(key) {
    let columns = [];
    let currentState = this.state[key];
    // get all the "other" ew degrades values
    let stateKeys = ["degFL", "degFR", "degAL", "degAR"].filter(s => (s !== key));
    let { degFL, degFR, degAL, degAR } = this.state;
    let states = { degFL, degFR, degAL, degAR };
    let otherEWDegdStillChecked = stateKeys.map(k => states[k]).filter(i => i).length > 0;

    let newState = !currentState;
    if (!newState && !otherEWDegdStillChecked) {
      columns = this.removeCol("ewDeg");
      this.setState({ [key]: newState, degEW: false, columns });
    } else if (newState) {
      columns = this.getColumns("ewDeg", "EW Degrades", 140);
      this.setState({ [key]: newState, degEW: false, columns });
    } else {
      this.setState({ [key]: newState, degEW: false });
    }
  }

  handleEWFailChange(key) {
    let columns = [];
    let currentState = this.state[key];

    // get all the "other" ew failures values
    let stateKeys = ["failFL", "failFR", "failAL", "failAR"].filter(s => (s !== key));
    let { failFL, failFR, failAL, failAR } = this.state;
    let states = { failFL, failFR, failAL, failAR };
    let otherEWFailsStillChecked = stateKeys.map(k => states[k]).filter(i => i).length > 0;

    let newState = !currentState;
    if (!newState && !otherEWFailsStillChecked) {
      columns = this.removeCol("ewFail");
      this.setState({ [key]: newState, failEW: false, columns });
    } else if (newState) {
      columns = this.getColumns("ewFail", "EW Failures", 140);
      this.setState({ [key]: newState, failEW: false, columns });
    } else {
      this.setState({ [key]: newState, failEW: false });
    }
  }

  colTitle(key) {
    const columns = {
      ewDeg: "EW Degrades Degrade",
      ewFail: "EW Failure Fails"
    }
    return columns[key];
  }

  handleCheckBoxChange(event, newValue) {
    const id = event.target.id;
    if (id === "degEW") {
      let columns = this.getColumns("ewDeg", "EW Degrades", 140);
      this.setState({
        columns,
        [id]: newValue,
        degFL: false,
        degFR: false,
        degAL: false,
        degAR: false,
      });
      if (!newValue) {
        columns = this.removeCol("ewDeg");
        this.setState({ columns })
      }
    } else if (id === "failEW") {
      let columns = this.getColumns("ewFail", "EW Failures", 140);
      this.setState({
        columns,
        [id]: newValue,
        failFL: false,
        failFR: false,
        failAL: false,
        failAR: false,
      });
      if (!newValue) {
        columns = this.removeCol("ewFail");
        this.setState({ columns })
      }
    } else {
      let columns = [];
      if (newValue) {
        columns = this.getColumns(id, colLabels[id], 140);
      } else {
        columns = this.removeCol(id);
      }
      this.setState({ [id]: newValue, columns });
    }
  }

  dateFilter(startDate, endDate, data) {
    if (!data) { return [] };
    return data.filter(r => this.dateIsInRange(startDate, endDate, r.date));
  }

  handleDateChange(key, event) {
    let val = event.target.value;
    this.setState({ [key]: val });
  }

  render() {
    let totalSorties = 0;
    let totalAircraftSorties = null;
    let {
      filteredData,
      menuIsOpen,
      flightTime,
      hms,
      hmr,
      hma,
      degEW,
      degFL,
      degFR,
      degAL,
      degAR,
      failEW,
      failFL,
      failFR,
      failAL,
      failAR,
      degDAS,
      failDAS,
      degBA,
      degBF,
      degLS,
      degRS,
      degTA,
      degTF,
      failBA,
      failBF,
      failLS,
      failRS,
      failTA,
      failTF,
      radarDeg,
      commsDeg,
      navDeg,
      iffDeg,
      intgtrDeg,
      eotsDeg,
      l16Deg,
      madlDeg,
      smsDeg,
      cmDeg,
      radarFail,
      commsFail,
      navFail,
      iffFail,
      intgtrFail,
      eotsFail,
      l16Fail,
      madlFail,
      smsFail,
      cmFail,
      icaw,
      tacticalMissionType,
      squadron,
      aircraft,
      degradesAffectMission,
      wereSparesRequired,
      wereSparesAvailable,
      flightLeadForEvent,
      issuesLedToAbort,
      postFlightVSBit,
      coldIronRequired,
      startDate,
      endDate,
    } = this.state;
    filteredData = filteredData.map((item, i) => ({ id: i, ...item }));
    if (startDate && endDate) {
      filteredData = this.dateFilter(startDate, endDate, filteredData);
      totalSorties = filteredData.length;
    }

    // aircraft needs to come first to set the correct totalAircraftSorties
    if (aircraft) {
      filteredData = this.textMatch(filteredData, 'aircraft', aircraft);
      totalAircraftSorties = filteredData.length;
    } else {
      totalAircraftSorties = null;
    }

    if (squadron) {
      filteredData = this.textMatch(filteredData, 'squadron', squadron);
    } else {
    }
    if (icaw) { 
      filteredData = filteredData
        .filter(item => 
          (item.extraDiscrepancies || []).map(disc => disc.icaw)
          .some(r => icaw === r)
        );
    }
    if (tacticalMissionType) { 
      filteredData = this.textMatch(filteredData, 'tacticalMissionType', tacticalMissionType);
    }

    // EW degrades
    if (degEW) {
      filteredData = this.withDegOrFail(filteredData, "ew", "deg");
    }
    if (degFL) {
      filteredData = this.doesEwOrDasHaveSpecificQuadDeg(filteredData, "ew", 0)
    }
    if (degFR) {
      filteredData = this.doesEwOrDasHaveSpecificQuadDeg(filteredData, "ew", 1)
    }
    if (degAL) {
      filteredData = this.doesEwOrDasHaveSpecificQuadDeg(filteredData, "ew", 2)
    }
    if (degAR) {
      filteredData = this.doesEwOrDasHaveSpecificQuadDeg(filteredData, "ew", 3)
    }
    // --
    if (flightTime) {
      filteredData = this.withinFlightTimeRange(filteredData, flightTime);
    }
    if (failEW) {
      filteredData = this.withDegOrFail(filteredData, "ew", "fail");
    }
    if (failFL) {
      filteredData = this.doesEwOrDasHaveSpecificQuadFail(filteredData, "ew", 0)
    }
    if (failFR) {
      filteredData = this.doesEwOrDasHaveSpecificQuadFail(filteredData, "ew", 1)
    }
    if (failAL) {
      filteredData = this.doesEwOrDasHaveSpecificQuadFail(filteredData, "ew", 2)
    }
    if (failAR) {
      filteredData = this.doesEwOrDasHaveSpecificQuadFail(filteredData, "ew", 3)
    }

    if (degDAS) {
      filteredData = this.withDegOrFail(filteredData, "das", "deg");
    }
    if (degBA) {
      filteredData = this.doesEwOrDasHaveSpecificQuadDeg(filteredData, "das", 0)
    }
    if (degBF) {
      filteredData = this.doesEwOrDasHaveSpecificQuadDeg(filteredData, "das", 1)
    }
    if (degLS) {
      filteredData = this.doesEwOrDasHaveSpecificQuadDeg(filteredData, "das", 2)
    }
    if (degRS) {
      filteredData = this.doesEwOrDasHaveSpecificQuadDeg(filteredData, "das", 3)
    }
    if (degTA) {
      filteredData = this.doesEwOrDasHaveSpecificQuadDeg(filteredData, "das", 4)
    }
    if (degTF) {
      filteredData = this.doesEwOrDasHaveSpecificQuadDeg(filteredData, "das", 5)
    }
    if (failDAS) {
      filteredData = this.withDegOrFail(filteredData, "das", "fail");
    }
    if (failBA) {
      filteredData = this.doesEwOrDasHaveSpecificQuadFail(filteredData, "das", 0)
    }
    if (failBF) {
      filteredData = this.doesEwOrDasHaveSpecificQuadFail(filteredData, "das", 1)
    }
    if (failLS) {
      filteredData = this.doesEwOrDasHaveSpecificQuadFail(filteredData, "das", 2)
    }
    if (failRS) {
      filteredData = this.doesEwOrDasHaveSpecificQuadFail(filteredData, "das", 3)
    }
    if (failTA) {
      filteredData = this.doesEwOrDasHaveSpecificQuadFail(filteredData, "das", 4)
    }
    if (failTF) {
      filteredData = this.doesEwOrDasHaveSpecificQuadFail(filteredData, "das", 5)
    }
    if (radarDeg) {
      filteredData = this.withDegOrFail(filteredData, "radar", "deg");
    }
    if (commsDeg) {
      filteredData = this.withDegOrFail(filteredData, "comms", "deg");
    }
    if (navDeg) {
      filteredData = this.withDegOrFail(filteredData, "nav", "deg");
    }
    if (iffDeg) {
      filteredData = this.withDegOrFail(filteredData, "iff", "deg");
    }
    if (intgtrDeg) {
      filteredData = this.withDegOrFail(filteredData, "intgtr", "deg");
    }
    if (eotsDeg) {
      filteredData = this.withDegOrFail(filteredData, "eots", "deg");
    }
    if (l16Deg) {
      filteredData = this.withDegOrFail(filteredData, "l16", "deg");
    }
    if (madlDeg) {
      filteredData = this.withDegOrFail(filteredData, "madl", "deg");
    }
    if (smsDeg) {
      filteredData = this.withDegOrFail(filteredData, "sms", "deg");
    }
    if (cmDeg) {
      filteredData = this.withDegOrFail(filteredData, "cm", "deg");
    }
    if (radarFail) {
      filteredData = this.withDegOrFail(filteredData, "radar", "fail");
    }
    if (commsFail) {
      filteredData = this.withDegOrFail(filteredData, "comms", "fail");
    }
    if (navFail) {
      filteredData = this.withDegOrFail(filteredData, "nav", "fail");
    }
    if (iffFail) {
      filteredData = this.withDegOrFail(filteredData, "iff", "fail");
    }
    if (intgtrFail) {
      filteredData = this.withDegOrFail(filteredData, "intgtr", "fail");
    }
    if (eotsFail) {
      filteredData = this.withDegOrFail(filteredData, "eots", "fail");
    }
    if (l16Fail) {
      filteredData = this.withDegOrFail(filteredData, "l16", "fail");
    }
    if (madlFail) {
      filteredData = this.withDegOrFail(filteredData, "madl", "fail");
    }
    if (smsFail) {
      filteredData = this.withDegOrFail(filteredData, "sms", "fail");
    }
    if (cmFail) {
      filteredData = this.withDegOrFail(filteredData, "cm", "fail");
    }

    if (degradesAffectMission) {
      filteredData = this.itemIsTrue(filteredData, "degradesAffectMission");
    }
    if (wereSparesRequired) {
      filteredData = this.itemIsTrue(filteredData, "wereSparesRequired");
    }
    if (wereSparesAvailable) {
      filteredData = this.sparesAvailable(filteredData);
    }
    if (flightLeadForEvent) {
      filteredData = this.itemIsTrue(filteredData, "flightLeadForEvent");
    }
    if (issuesLedToAbort) {
      filteredData = this.itemIsTrue(filteredData, "issuesLedToAbort");
    }
    if (postFlightVSBit) {
      filteredData = this.itemIsTrue(filteredData, "postFlightVSBit");
    }
    if (coldIronRequired) {
      filteredData = this.itemIsTrue(filteredData, "coldIronRequired");
    }
    if (hms && hms !== 'any') {
      filteredData = this.itemEqVal(filteredData, "numberOfAircraft", hms);
    }
    if (hmr === 0 || (hmr && hmr !== 'any')) {
      filteredData = this.itemEqVal(filteredData, "howManyRequired", hmr);
    }
    if (hma === 0 || (hma && hma !== 'any')) {
      filteredData = this.itemEqVal(filteredData, "howManyAvailable", hma);
    }

    if (!filteredData) {
      return ( <div className="Search"><div className="pageTitle">No Data Found</div></div> );
    } else {
      return (
        <div className="Search">
          <div className="SearchBody">
            <div className={`leftDrawer ${menuIsOpen ? '' : 'closed' }`}>
              <div className="topBar">
                <div className="topBarTitle">Filter Data</div>
                <div className="topBarIcon">
                  {menuIsOpen ? <ChevronLeftIcon onClick={this.handleCloseDrawer.bind(this)} /> : <MenuIcon onClick={this.handleOpenDrawer.bind(this)} />}
                </div>
              </div>
              <div className="drawerBody">
                <div className="clearButton">
                  <Button onClick={this.resetFilters.bind(this)} size="small" color="inherit">Clear Filters</Button>
                </div>
                <div className="filterItem">
                  <Typography>
                    Date Range
                  </Typography>
                  <TextField
                    id="startDate"
                    label="Start"
                    onChange={this.handleDateChange.bind(this, 'startDate')}
                    type="date"
                    value={this.state.startDate}
                    className="datePicker"
                  />
                  <TextField
                    id="endDate"
                    label="End"
                    onChange={this.handleDateChange.bind(this, 'endDate')}
                    type="date"
                    value={this.state.endDate}
                    className="datePicker"
                  />
                </div>
                <div><b>Total Sorties: {totalSorties}</b></div>

                <Autocomplete
                  id="squadron"
                  className="filterTextSearch"
                  options={squadronOptions}
                  inputValue={this.state.squadron || ''}
                  getOptionLabel={(option) => option.title}
                  onChange={this.handleTextSearchChange.bind(this, "squadron")}
                  style={{ width: 300 }}
                  renderInput={(params) => <TextField {...params} label="Squadron" variant="outlined" />}
                />

                <Autocomplete
                  id="aircraft"
                  className="filterTextSearch"
                  options={aircraftOptions}
                  inputValue={this.state.aircraft || ''}
                  getOptionLabel={(option) => option.title}
                  onChange={this.handleTextSearchChange.bind(this, "aircraft")}
                  style={{ width: 300 }}
                  renderInput={(params) => <TextField {...params} label="Aircraft" variant="outlined" />}
                />

                <Autocomplete
                  id="icaw"
                  className="filterTextSearch"
                  options={icawOptions}
                  inputValue={this.state.icaw || ''}
                  getOptionLabel={(option) => option.title}
                  renderTags={(options) => options.map((o, i) => (
                      <span key={`icawOption-${i}`} className="tagClass" style={{ backgroundColor: o.bg, color: o.font, padding: "2px 7px", margin: "2px" }}>{o.title}</span>
                    ))
                  }
                  renderInput={(params) => {
                    let ic = this.state.icaw;
                    return (
                      <TextField {...params}
                        className={icawClasses[ic]}
                        label="ICAW"
                        variant="outlined" />
                    )
                  }}
                  renderOption={(option) => {
                    return (
                      <span style={{ backgroundColor: option.bg, color: option.font, padding: "2px 7px", margin: "0px" }}>{option.title}</span>
                    )
                  }}
                  onChange={this.handleTextSearchChange.bind(this, "icaw")}
                  style={{ width: 300 }}
                />

                <Autocomplete
                  id="tacticalMissionType"
                  className="filterTextSearch"
                  options={missionTypeOptions}
                  inputValue={this.state.tacticalMissionType || ''}
                  getOptionLabel={(option) => option.title}
                  onChange={this.handleTextSearchChange.bind(this, "tacticalMissionType")}
                  style={{ width: 300 }}
                  renderInput={(params) => <TextField {...params} label="Tactical Mission Type" variant="outlined" />}
                />



                <div className="filterItem">
                  <Accordion>
                    <AccordionSummary
                      expandIcon={<ExpandMoreIcon />}
                      aria-controls="ewDegrades"
                      id="ewDegrades"
                    >
                      <Typography>Mission Systems</Typography>
                    </AccordionSummary>
                    <AccordionDetails>

                      <div className="filterItem">
                        <Accordion>
                          <AccordionSummary
                            expandIcon={<ExpandMoreIcon />}
                            aria-controls="ewDegrades"
                            id="ewDegrades"
                          >
                            <Typography>EW </Typography>
                          </AccordionSummary>
                          <AccordionDetails>

                            <div>
                              <h3>Degrades</h3>
                              {ewDegsFails.map((degCode, i) => 
                                (<div key={`edCode-${i}`} className="checkBoxGroup">
                                  <FormControlLabel
                                    control={
                                      <Checkbox
                                        checked={this.state[`deg${degCode}`]}
                                        id={`deg${degCode}`}
                                        onChange={this.handleCheckBoxChange.bind(this)}
                                        color="primary"
                                      />
                                    }
                                    label={degCode === 'EW' ? 'Any' : degCode}
                                  />
                                </div>)
                              )}

                              <div className="degSelector ewSelector">
                                <div className="dsInner">
                                  <img alt="bullseye" className="quadTarget" src="/target.svg"/>
                                  <div onClick={this.handleEWDegChange.bind(this, 'degFL')} className={`quarter left-top ${this.state.degFL ? 'selectedDegrade' : ''}`}></div>
                                  <div onClick={this.handleEWDegChange.bind(this, 'degFR')} className={`quarter right-top ${this.state.degFR ? 'selectedDegrade' : ''}`}></div>
                                  <div onClick={this.handleEWDegChange.bind(this, 'degAL')} className={`quarter left-bottom ${this.state.degAL ? 'selectedDegrade' : ''}`}></div>
                                  <div onClick={this.handleEWDegChange.bind(this, 'degAR')} className={`quarter right-bottom ${this.state.degAR ? 'selectedDegrade' : ''}`}></div>
                                </div>
                              </div>
                            </div>

                            <div>
                              <h3>Failures</h3>
                              {ewDegsFails.map((failCode, i) => 
                                (<div key={`bKey-${i}`} className="checkBoxGroup">
                                  <FormControlLabel
                                    control={
                                      <Checkbox
                                        checked={this.state[`fail${failCode}`]}
                                        id={`fail${failCode}`}
                                        onChange={this.handleCheckBoxChange.bind(this)}
                                        color="primary"
                                      />
                                    }
                                    label={failCode === 'EW' ? 'Any' : failCode}
                                  />
                                </div>)
                              )}

                              <div className="degSelector ewSelector">
                                <div className="dsInner">
                                  <img alt="bullseye" className="quadTarget" src="/target.svg"/>
                                  <div onClick={this.handleEWFailChange.bind(this, 'failFL')} className={`quarter left-top ${this.state.failFL ? 'selectedFail' : ''}`}></div>
                                  <div onClick={this.handleEWFailChange.bind(this, 'failFR')} className={`quarter right-top ${this.state.failFR ? 'selectedFail' : ''}`}></div>
                                  <div onClick={this.handleEWFailChange.bind(this, 'failAL')} className={`quarter left-bottom ${this.state.failAL ? 'selectedFail' : ''}`}></div>
                                  <div onClick={this.handleEWFailChange.bind(this, 'failAR')} className={`quarter right-bottom ${this.state.failAR ? 'selectedFail' : ''}`}></div>
                                </div>
                              </div>
                            </div>

                          </AccordionDetails>
                        </Accordion>
                      </div>



                      <div className="filterItem">
                        <Accordion>
                          <AccordionSummary
                            expandIcon={<ExpandMoreIcon />}
                            aria-controls="ewDegrades"
                            id="ewDegrades"
                          >
                            <Typography>DAS</Typography>
                          </AccordionSummary>
                          <AccordionDetails>

                          <div className="filterItem">
                            <Accordion>
                              <AccordionSummary
                                expandIcon={<ExpandMoreIcon />}
                                aria-controls="ewDegrades"
                                id="ewDegrades"
                              >
                                <Typography>Degrades</Typography>
                              </AccordionSummary>
                              <AccordionDetails>
                                {dasDegsFails.map((degCode, i) => 
                                  (<div key={`cKey-${i}`} className="checkBoxGroup">
                                    <FormControlLabel
                                      control={
                                        <Checkbox
                                          checked={this.state[`deg${degCode}`]}
                                          id={`deg${degCode}`}
                                          onChange={this.handleDASCheckBoxChange.bind(this)}
                                          color="primary"
                                        />
                                      }
                                      label={degCode === 'DAS' ? 'Any' : degCode}
                                    />
                                  </div>)
                                )}
                              </AccordionDetails>
                            </Accordion>
                          </div> 

                          <div className="filterItem">
                            <Accordion>
                              <AccordionSummary
                                expandIcon={<ExpandMoreIcon />}
                                aria-controls="ewDegrades"
                                id="ewDegrades"
                              >
                                <Typography>Failures</Typography>
                              </AccordionSummary>
                              <AccordionDetails>
                                {dasDegsFails.map((failCode, i) => 
                                  (<div key={`dKey-${i}`} className="checkBoxGroup">
                                    <FormControlLabel
                                      control={
                                        <Checkbox
                                          checked={this.state[`fail${failCode}`]}
                                          id={`fail${failCode}`}
                                          onChange={this.handleDASCheckBoxChange.bind(this)}
                                          color="primary"
                                        />
                                      }
                                      label={failCode === 'DAS' ? 'Any' : failCode}
                                    />
                                  </div>)
                                )}
                              </AccordionDetails>
                            </Accordion>
                          </div> 

                          </AccordionDetails>
                        </Accordion>
                      </div>



                      {otherDegsFails.map((code, i) => 
                        (<div key={`eKey-${i}`} className="filterItem">
                        <Accordion>
                          <AccordionSummary
                            expandIcon={<ExpandMoreIcon />}
                            aria-controls="ewDegrades"
                            id="ewDegrades"
                          >
                            <Typography>{codeLabels[code]}</Typography>
                          </AccordionSummary>
                          <AccordionDetails>
                            
                            <div>
                              <div className="checkBoxGroup">
                                <FormControlLabel
                                  control={
                                    <Checkbox
                                      checked={this.state[`${code}Deg`]}
                                      id={`${code}Deg`}
                                      onChange={this.handleCheckBoxChange.bind(this)}
                                      color="primary"
                                    />
                                  }
                                  label="Degrade"
                                />
                              </div>
                            </div>

                            <div>
                              <div className="checkBoxGroup">
                                <FormControlLabel
                                  control={
                                    <Checkbox
                                      checked={this.state[`${code}Fail`]}
                                      id={`${code}Fail`}
                                      onChange={this.handleCheckBoxChange.bind(this)}
                                      color="primary"
                                    />
                                  }
                                  label="Failure"
                                />
                              </div>
                            </div>

                          </AccordionDetails>
                        </Accordion>
                      </div>))}


                    </AccordionDetails>
                  </Accordion>
                </div>



                <div className="filterItem">
                  <Accordion>
                    <AccordionSummary
                      expandIcon={<ExpandMoreIcon />}
                      aria-controls="Miscellaneous"
                      id="miscellaneous"
                    >
                      <Typography>Miscellaneous</Typography>
                    </AccordionSummary>
                    <AccordionDetails>

                      <div className="filterItem">
                        <FormControlLabel
                          control={
                            <Checkbox
                              checked={this.state.issuesLedToAbort}
                              id="issuesLedToAbort"
                              onChange={this.handleCheckBoxChange.bind(this)}
                              color="primary"
                            />
                          }
                          label="In Flight Mission Abort"
                        />
                      </div>
                      <div className="filterItem">
                        <FormControlLabel
                          control={
                            <Checkbox
                              checked={this.state.degradesAffectMission}
                              id="degradesAffectMission"
                              onChange={this.handleCheckBoxChange.bind(this)}
                              color="primary"
                            />
                          }
                          label="Degrades Affect Mission"
                        />
                      </div>
                      <div className="filterItem">
                        <FormControlLabel
                          control={
                            <Checkbox
                              checked={this.state.postFlightVSBit}
                              id="postFlightVSBit"
                              onChange={this.handleCheckBoxChange.bind(this)}
                              color="primary"
                            />
                          }
                          label="Post Flight VS Bit No Go"
                        />
                      </div>
                      <div className="filterItem">
                        <FormControlLabel
                          control={
                            <Checkbox
                              checked={this.state.coldIronRequired}
                              id="coldIronRequired"
                              onChange={this.handleCheckBoxChange.bind(this)}
                              color="primary"
                            />
                          }
                          label="Cold Iron Required"
                        />
                      </div>

                      <div className="filterItem">
                        <Accordion>
                          <AccordionSummary
                            expandIcon={<ExpandMoreIcon />}
                            aria-controls="Spare Aircraft"
                            id="spareAircraft"
                          >
                            <Typography>Spare Aircraft</Typography>
                          </AccordionSummary>
                          <AccordionDetails>

                            <div className="filterItem">
                              <FormControlLabel
                                control={
                                  <Checkbox
                                    checked={this.state.wereSparesRequired}
                                    id="wereSparesRequired"
                                    onChange={this.handleCheckBoxChange.bind(this)}
                                    color="primary"
                                  />
                                }
                                label="Spares Required"
                              />
                            </div>  

                            <div className="filterItem">
                              <FormControlLabel
                                control={
                                  <Checkbox
                                    checked={this.state.wereSparesAvailable}
                                    id="wereSparesAvailable"
                                    onChange={this.handleCheckBoxChange.bind(this)}
                                    color="primary"
                                  />
                                }
                                label="Spares Available"
                              />
                            </div> 

                            <div className="filterItem">
                              <Accordion>
                                <AccordionSummary
                                  expandIcon={<ExpandMoreIcon />}
                                  aria-controls="hms"
                                  id="hms"
                                >
                                  <Typography># Aircraft Scheduled</Typography>
                                </AccordionSummary>
                                <AccordionDetails>

                                  {aircraftQuestions.map((qkey, i) => 
                                    (<div key={`gKey-${i}`} className="checkBoxGroup">
                                      <FormControlLabel
                                        control={
                                          <Checkbox
                                            checked={this.state['hms'] === qkey}
                                            id={'hms'}
                                            onChange={this.handleAQChange.bind(this, 'hms', qkey)}
                                            color="primary"
                                          />
                                        }
                                        label={qkeyLabels[`label_${qkey}`]}
                                      />
                                    </div>)
                                  )}
                                </AccordionDetails>
                              </Accordion>
                            </div>



                            <div className="filterItem">
                              <Accordion>
                                <AccordionSummary
                                  expandIcon={<ExpandMoreIcon />}
                                  aria-controls="hms"
                                  id="hms"
                                >
                                  <Typography># Aircraft Required</Typography>
                                </AccordionSummary>
                                <AccordionDetails>

                                  {aircraftQuestionsB.map((qkey, i) => 
                                    (<div key={`hKey-${i}`} className="checkBoxGroup">
                                      <FormControlLabel
                                        control={
                                          <Checkbox
                                            checked={this.state['hmr'] === qkey}
                                            id={'hmr'}
                                            onChange={this.handleAQChange.bind(this, 'hmr', qkey)}
                                            color="primary"
                                          />
                                        }
                                        label={qkeyLabels[`label_${qkey}`]}
                                      />
                                    </div>)
                                  )}
                                </AccordionDetails>
                              </Accordion>
                            </div>

                            <div className="filterItem">
                              <Accordion>
                                <AccordionSummary
                                  expandIcon={<ExpandMoreIcon />}
                                  aria-controls="hms"
                                  id="hms"
                                >
                                  <Typography># Aircraft Available</Typography>
                                </AccordionSummary>
                                <AccordionDetails>

                                  {aircraftQuestionsB.map((qkey, i) => 
                                    (<div key={`iKey-${i}`} className="checkBoxGroup">
                                      <FormControlLabel
                                        control={
                                          <Checkbox
                                            checked={this.state['hma'] === qkey}
                                            id={'hma'}
                                            onChange={this.handleAQChange.bind(this, 'hma', qkey)}
                                            color="primary"
                                          />
                                        }
                                        label={qkeyLabels[`label_${qkey}`]}
                                      />
                                    </div>)
                                  )}
                                </AccordionDetails>
                              </Accordion>
                            </div>


                          </AccordionDetails>
                        </Accordion>
                      </div>

                    </AccordionDetails>
                  </Accordion>
                </div>

   
              </div>
            </div>
            <div className="SearchResults">
              <div style={{ height: "900px", width: "100%" }}>
                {totalAircraftSorties && <div className="gridHeaders">{aircraft}: {totalAircraftSorties} SORTIES</div>}
                <div className="gridHeaders">SELECTED FILTERS: {filteredData.length} OCCURRENCES</div>
                <DataGrid rows={filteredData} columns={this.state.columns} pageSize={15} />
              </div>
            </div>
          </div>
        </div>
      );
    }
  }
}
