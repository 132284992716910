import React, { Component } from "react";
import { FormGroup } from "react-bootstrap";
import Alert from '@material-ui/lab/Alert';
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import LoaderButton from "../components/LoaderButton";
import FormControlLabel from '@material-ui/core/FormControlLabel';
import Select from '@material-ui/core/Select';
import MenuItem from '@material-ui/core/MenuItem';
import TextField from '@material-ui/core/TextField';
import InputLabel from '@material-ui/core/InputLabel';
import Grid from '@material-ui/core/Grid';
import Cookies from 'universal-cookie';
import Checkbox from '@material-ui/core/Checkbox';
import config from "../config";
import moment from "moment";
import "./NewData.css";
import { API } from "aws-amplify";

const cookies = new Cookies();

export default class NewData extends Component {
  constructor(props) {
    super(props);

    this.file = null;
    this.form = React.createRef();
    this.documentFile = null;
    this.visibilityOptions = ['Day', 'Night'];

    const pfd = cookies.get('pfd') || {};
    // pfd -->
    //
    // date: "2020-07-07"
    // discrepancies: (2) [{…}, {…}]
    // tailNumber: "4F34"
    // takeOffTime: {hour: 6, minute: 0}

    let date = new Date();
    if (pfd && pfd.date) {
      date = new Date(pfd.date);
      const time = pfd.takeOffTime;
      if (time) {
        date.setHours(time.hour, time.minute, 0);
      }
    }


    const discs = pfd.discrepancies;
    let icawTitle = "";
    let icawDescription = "";
    if (discs && discs[0]) {
      icawTitle = discs[0]["icaw"];
      icawDescription = discs[0]["desc"];
    }

    this.state = {
      recentDoc: null,
      showSuccessMessage: false,
      content: {
        title: icawTitle,
        location: "KNLC",
        squadron: "VFA-125",
        reportType: "OR",
        variant: "F-35C",
        buno: pfd.tailNumber || "",
        missionType: pfd.missionType || "",
        weather: "",
        ifeDeclared: false,
        pfoFlown: false,
        arcmms: "",
        description: icawDescription,
        visibility: "Day",
        date: date,
        dataType: "Occurrence"
      },
      isLoading: null,
    };
  }

  validateTitle() {
    return this.state.content.title.length > 0;
  }

  handleReportTypeChange(event) {
    let content = JSON.parse(JSON.stringify(this.state.content));
    let contentClone = {
      ...content,
      reportType: event.target.value
    };
    this.setState({ content: contentClone });
  }

  validateForm() {
    return this.validateTitle();
  }

  validateDocumentForm() {
    return true
  }

  getDocCodeFromRecentCode(code, rt, date) {
    let docCode = '';
    let endStr = '';
    let mostRecentDocCode = '';
    let lastChar = '';
    let newLastChar = '';
    if (!code) {
      // this is the first doc of the day
      docCode = `${rt}-${date}-1A`;
    } else {
      mostRecentDocCode = code;
      lastChar = String.fromCharCode(mostRecentDocCode.charCodeAt(mostRecentDocCode.length - 1));
      if (lastChar !== "Z") {
        endStr = mostRecentDocCode.substring(0, mostRecentDocCode.length - 1) + String.fromCharCode(mostRecentDocCode.charCodeAt(mostRecentDocCode.length - 1) + 1);
        docCode = `${rt}${endStr.substring(2, endStr.length)}`;
      } else {
        endStr = mostRecentDocCode.substring(0, mostRecentDocCode.length - 2) + String.fromCharCode(mostRecentDocCode.charCodeAt(mostRecentDocCode.length - 2) + 1) + "A";
        docCode = `${rt}${endStr.substring(2, endStr.length)}`;
      }
    }  
    return docCode;
  }

  handleOccurrenceTextChange = event => {
    let content = JSON.parse(JSON.stringify(this.state.content));
    this.setState({
      content: {
        ...content,
        [event.target.id]: event.target.value
      }
    });
  }

  handleDocumentTextChange = event => {
    let content = JSON.parse(JSON.stringify(this.state.content));
    this.setState({
      content: {
        ...content,
        [event.target.id]: event.target.value
      }
    });
  }

  handleChange = event => {
    this.setState({
      [event.target.id]: event.target.value
    });
  }

  handleOccurrenceCheckboxChange = event => {
    let content = JSON.parse(JSON.stringify(this.state.content));
    this.setState({
      content: {
        ...content,
        [event.target.id]: event.target.checked
      }
    });
  }  

  handleVisibilityChange = event => {
    let content = JSON.parse(JSON.stringify(this.state.content));
    let contentClone = {
      ...content,
      visibility: event.target.value
    };
    this.setState({ content: contentClone });
  }

  handleDateChange = date => {
    let content = JSON.parse(JSON.stringify(this.state.content));
    this.setState({
      content: {
        ...content,
        date
      }
    });
  }

  handleFileChange = event => {
    this.file = event.target.files[0];
  }

  handleDocumentFileChange = event => {
    this.documentFile = event.target.files[0];
  }

  clean(obj) {
    for (var propName in obj) { 
      if (obj[propName] === null || obj[propName] === undefined || obj[propName] === "") {
        delete obj[propName];
      }
    }
  }

  createDataKey(type, year, mo, aircraft = 'f35com') {
    return `sr-${type.toLowerCase()}-${year}-${mo}-${aircraft}`;
  }

  sendEmail(data) {
    return API.post("data", "/emailer", {
      headers: {
          "Access-Control-Allow-Headers" : "Content-Type",
          "Access-Control-Allow-Origin": '*',
          "Access-Control-Allow-Methods": "OPTIONS,POST,GET"
      },
      body: data
    });
  }

  submitForm() {
    const content = this.state.content;
    if (this.file) {
      content.file = this.file;
    }
    let formData = new FormData();
    for (let key in content) {
      formData.append(key, content[key]);
    }
    const xhr = new XMLHttpRequest();
    xhr.open("POST", "https://formspree.io/mqkgzdan");
    xhr.setRequestHeader("Accept", "application/json");
    xhr.onreadystatechange = () => {
      if (xhr.readyState !== XMLHttpRequest.DONE) return;
      if (xhr.status === 200) {
        this.setState({ status: "SUCCESS" });
      } else {
        this.setState({ status: "ERROR" });
      }
    };
    xhr.send(formData);
    window.scrollTo(0, 0);
  }

  handleSubmit = async event => {
    event.preventDefault();
    this.setState({ isLoading: true });

    let monthNames = [
      "Jan", "Feb", "Mar",
      "Apr", "May", "Jun", "Jul",
      "Aug", "Sep", "Oct",
      "Nov", "Dec"
    ];

    const type = this.state.content.reportType;
    const reportDate = new Date(this.state.content.date).toLocaleDateString();
    const [month,, year] = reportDate.split('/');
    const mo = monthNames[month - 1].toLowerCase();
    const monthFormatted = month < 10 ? `0${month}` : month;
    const yr = year.substring(2,5);
    const docCodeDate = `${monthFormatted}${yr}`;
    const dataKey = this.createDataKey(type, yr, mo) // TODO: add aircraft from aircraft type input

    // get start of month datetime
    let date = new Date();
    let startOfMonth = new Date(date.getFullYear(), date.getMonth(), 1);
    startOfMonth.setHours(0,0,0,0);


    const recentDoc = this.props.data.sort((a, b) => { 
      return new Date(b.createdAt) - new Date(a.createdAt) 
    }).filter((doc) => ((doc.createdAt > startOfMonth) && (doc.content.docCode) && doc.content.docCode.substring(0,2).toUpperCase() === type.toUpperCase()))[0];
    const mostRecentDocCode = ((recentDoc || {})["content"] || {})["docCode"];
    const docCode = await this.getDocCodeFromRecentCode(mostRecentDocCode, type, docCodeDate);

    let contentState = JSON.parse(JSON.stringify(this.state.content));
    let content = {
      ...contentState,
      docCode,
      dataKey,
      dataType: 'report'
    };
    await this.clean(content);

    if (!this.file) {
      try {
        const data = await this.createData({
          content
        });
        this.props.updateData(data);
        this.props.history.push("/");
      } catch (e) {
        alert(e);
        this.setState({ isLoading: false });
      }
    } else if (this.file.size > config.MAX_ATTACHMENT_SIZE) {
      alert(`Please pick a file smaller than ${config.MAX_ATTACHMENT_SIZE/1000000} MB.`);
      return;
    } else {
      try {
        const data = await this.createData({ content });
        this.props.updateData(data);
        this.props.history.push("/");
      } catch (e) {
        alert(e);
        this.setState({ isLoading: false });
      }
    }
    this.submitForm(event);
  }

  createData(data) {
    return API.post("data", "/data", {
      body: data
    });
  }

  render() {
    return (
      <div className="NewData">
        {this.state.showSuccessMessage && <div>
          <Alert severity="success">Your report was successfully submitted.</Alert>
        </div>}
        <h2>New Report</h2>
        <form
          ref={this.form}
          action="https://formspree.io/mqkyynjl"
          method="POST"
        >
          <div>
            <Grid container spacing={3}>
              <Grid item xs={12} md={7}>
                <TextField
                  className="formTextbox"
                  id="title"
                  label="Title"
                  value={this.state.content.title}
                  onChange={this.handleOccurrenceTextChange}
                  margin="dense"
                  variant="outlined"
                />
              </Grid>
              <Grid item xs={8} md={3}>
                <InputLabel htmlFor="date">Date & Time of Event</InputLabel>
                <FormGroup controlId="date">
                  <DatePicker
                    selected={moment(this.state.content.date).toDate()}
                    onChange={this.handleDateChange}
                    showTimeSelect={true}
                    dateFormat="Pp"
                  />
                </FormGroup>
              </Grid>
              <Grid item xs={4} md={2}>
                <InputLabel htmlFor="visibility">Day/Night</InputLabel>
                <Select
                  value={this.state.content.visibility}
                  onChange={this.handleVisibilityChange}
                  inputProps={{
                    name:'visibility',
                    id: 'visibility'
                  }}
                >          
                  <MenuItem value="Day">Day</MenuItem>
                  <MenuItem value="Night">Night</MenuItem>
                </Select>
              </Grid>

              <Grid item xs={4} md={2}>
                <InputLabel htmlFor="docType">Report Type</InputLabel>
                <Select
                  value={this.state.content.reportType}
                  onChange={this.handleReportTypeChange.bind(this)}
                  inputProps={{
                    name:'reportType',
                    id: 'reportType'
                  }}
                >          
                  <MenuItem value="OR">Occurrence Report</MenuItem>
                  <MenuItem value="AR">Action Request</MenuItem>
                </Select>
              </Grid>

              <Grid item xs={12} md={6}>
                <TextField
                  className="formTextbox"
                  id="location"
                  label="Location/Base"
                  value={this.state.content.location}
                  onChange={this.handleOccurrenceTextChange}
                  margin="dense"
                  variant="outlined"
                />
              </Grid>
              <Grid item xs={12} md={6}>
                <TextField
                  className="formTextbox"
                  id="squadron"
                  label="Squadron"
                  value={this.state.content.squadron}
                  onChange={this.handleOccurrenceTextChange}
                  margin="dense"
                  variant="outlined"
                />
              </Grid>
              <Grid item xs={12} md={6}>
                <TextField
                  className="formTextbox"
                  id="variant"
                  label="Variant"
                  value={this.state.content.variant}
                  onChange={this.handleOccurrenceTextChange}
                  margin="dense"
                  variant="outlined"
                />
              </Grid>
              <Grid item xs={12} md={6}>
                <TextField
                  className="formTextbox"
                  id="buno"
                  label="BUNO/Tail Number"
                  value={this.state.content.buno}
                  onChange={this.handleOccurrenceTextChange}
                  margin="dense"
                  variant="outlined"
                />
              </Grid>
              <Grid item xs={12} md={6}>
                <TextField
                  className="formTextbox"
                  id="missionType"
                  label="Mission Type"
                  value={this.state.content.missionType}
                  onChange={this.handleOccurrenceTextChange}
                  margin="dense"
                  variant="outlined"
                />
              </Grid>
              <Grid item xs={12} md={6}>
                <TextField
                  className="formTextbox"
                  id="weather"
                  label="Weather (if a factor)"
                  value={this.state.content.weather}
                  onChange={this.handleOccurrenceTextChange}
                  margin="dense"
                  variant="outlined"
                />
              </Grid>
              <Grid className="ifeDeclared" item xs={12} md={3}>
                <FormControlLabel
                  control={
                    <Checkbox
                      checked={this.state.content.ifeDeclared}
                      onChange={this.handleOccurrenceCheckboxChange}
                      id="ifeDeclared"
                    />
                  }
                  label="IFE Declared"
                />
              </Grid>
              <Grid className="ppoFlown" item xs={12} md={3}>
                <FormControlLabel
                  control={
                    <Checkbox
                      checked={this.state.content.pfoFlown}
                      onChange={this.handleOccurrenceCheckboxChange}
                      id="pfoFlown"
                    />
                  }
                  label="PFO Flown"
                />
              </Grid>
              <Grid item xs={12} md={12}>
                <TextField
                  className="formTextbox"
                  id="description"
                  label="Narrative (description of event and any concerns)"
                  value={this.state.content.description}
                  onChange={this.handleOccurrenceTextChange}
                  margin="dense"
                  variant="outlined"
                  multiline
                  rows={7}
                  rowsMax={10}
                />
              </Grid>
              <Grid item xs={12} md={12}>
                <LoaderButton
                  block
                  bsStyle="primary"
                  bsSize="large"
                  onClick={this.handleSubmit}
                  isLoading={this.state.isLoading}
                  text="Create Occurrence"
                  loadingText="Creating…"
                />
              </Grid>
            </Grid>
          </div>
        </form>
      </div>
    );
  }
}
