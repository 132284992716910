import React, { PureComponent } from "react";
import InputLabel from '@material-ui/core/InputLabel';
import MenuItem from '@material-ui/core/MenuItem';
import Select from '@material-ui/core/Select';
import FormControl from '@material-ui/core/FormControl';
import Button from '@material-ui/core/Button';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import Radio from '@material-ui/core/Radio';
import RadioGroup from '@material-ui/core/RadioGroup';
import {
  PieChart, Pie, Sector, Cell,
  LineChart, Line, XAxis, YAxis, CartesianGrid, Tooltip, Legend,
  BarChart, Bar
} from 'recharts';
import "./Dashboard.css";

Date.prototype.toDateInputValue = (function() {
    const local = new Date(this);
    local.setMinutes(this.getMinutes() - this.getTimezoneOffset());
    return local.toJSON().slice(0,10);
});

const toolTipStyles = {
  top: "-180px",
  zIndex: "99999",
  backgroundColor: "#fff",
  height: "190px",
  width: "350px",
  border: "1px solid #333",
  borderRadius: "5px"
};

function groupBy(arr, property) {
  return arr.reduce(function(memo, x) {
    if (!memo[x[property]]) { memo[x[property]] = []; }
    memo[x[property]].push(x);
    return memo;
  }, []);
}

const COLORS = ['#0088FE', '#00C49F', '#FFBB28', '#FF8042'];
function ran(min, max) {
  return Math.floor(Math.random() * (max - min) + min);
}
const MM = {
  fa: { min: 2, max: 7 },
  md: { min: 5, max: 10 },
  rs: { min: 7, max: 10 },
  sr: { min: 0, max: 4 },
  sa: { min: 3, max: 4 },
  ed: { min: 6, max: 8 },
  ef: { min: 0, max: 2 },
  dd: { min: 0, max: 2 },
  ls: { min: 0, max: 2 },
  rd: { min: 0, max: 2}
}

function cdr(mo, day) {
  const sr = ran(MM.sr.min,MM.sr.max);
  const sa_min = sr > 1 ? (sr - 2) : 0;
  return {
    month: mo,
    day: day,
    rs: ran(MM.rs.min,MM.rs.max), 
    fa: ran(MM.fa.min,MM.fa.max),
    md: ran(MM.md.min,MM.md.max),
    sr,
    sa: ran(sa_min,sr),
    ed: ran(MM.ed.min,MM.ed.max),
    ef: ran(MM.ef.min,MM.ef.max),
    dd: ran(MM.dd.min,MM.dd.max),
    ls: ran(MM.ls.min,MM.ls.max),
    rd: ran(MM.rd.min,MM.rd.max),
  }
}

const ALLMOS = [
  'Jan',
  'Feb',
  'Mar',
  'Apr',
  'May',
  'Jun',
  'Jul',
  'Aug',
  'Sep',
  'Oct',
  'Nov',
  'Dec'
]

const nth = function(d) {
  if (d > 3 && d < 21) return `${d}th`;
  switch (d % 10) {
    case 1:  return `${d}st`;
    case 2:  return `${d}nd`;
    case 3:  return `${d}rd`;
    default: return `${d}th`;
  }
}

const MONTHS = [
  'June',
  'July',
  'August',
  'September',
  'October',
  'November'
];

const MOABBR = [
  'Jun',
  'Jul',
  'Aug',
  'Sep',
  'Oct',
  'Nov'
];

const MO_DAYS = [
  [1,2,3,4,5,6,7,8,9,10,11,12,13,14,15,16,17,18,19,20,21,22,23,24,25,26,27,28,29,30,31],
  [1,2,3,4,5,6,7,8,9,10,11,12,13,14,15,16,17,18,19,20,21,22,23,24,25,26,27,28],
  [1,2,3,4,5,6,7,8,9,10,11,12,13,14,15,16,17,18,19,20,21,22,23,24,25,26,27,28,29,30,31],
  [1,2,3,4,5,6,7,8,9,10,11,12,13,14,15,16,17,18,19,20,21,22,23,24,25,26,27,28,29,30],
  [1,2,3,4,5,6,7,8,9,10,11,12,13,14,15,16,17,18,19,20,21,22,23,24,25,26,27,28,29,30,31],
  [1,2,3,4,5,6,7,8,9,10,11,12,13,14,15,16,17,18,19,20,21,22,23,24,25,26,27,28,29,30],
  [1,2,3,4,5,6,7,8,9,10,11,12,13,14,15,16,17,18,19,20,21,22,23,24,25,26,27,28,29,30,31],
  [1,2,3,4,5,6,7,8,9,10,11,12,13,14,15,16,17,18,19,20,21,22,23,24,25,26,27,28,29,30,31],
  [1,2,3,4,5,6,7,8,9,10,11,12,13,14,15,16,17,18,19,20,21,22,23,24,25,26,27,28,29,30],
  [1,2,3,4,5,6,7,8,9,10,11,12,13,14,15,16,17,18,19,20,21,22,23,24,25,26,27,28,29,30,31],
  [1,2,3,4,5,6,7,8,9,10,11,12,13,14,15,16,17,18,19,20,21,22,23,24,25,26,27,28,29,30],
  [1,2,3,4,5,6,7,8,9,10,11,12,13,14,15,16,17,18,19,20,21,22,23,24,25,26,27,28,29,30,31]
]

function createData() {
  const data = [];
  MO_DAYS.map((mo, i) => {
    mo.map((day) => {
      const record = cdr(i + 1, day);
      data.push(record);
    })
  });
  return data;
}

const allData = createData();

const SORTIE_KEYS = {
  rs: "Reported Sorties",
  fa: "FMC Aircraft",
  md: "Mission Degrades",
  sr: "Events Where Spares Required",
  sa: "Events Where Spares Available (if Required)"
}

const SORTIE_LABELS = {
  rs: "Event Data",
  fa: "FMC Aircraft",
  md: "PMC Aircraft",
  sr: "Spares Required",
  sa: "Spares Available"
}

const degradeMonthData = [
  { name: 'July', ed: 171, ef: 2, dd: 4, ls: 10, rd: 6 },
  { name: 'August', ed: 168, ef: 4, dd: 7, ls: 17, rd: 4 },
  { name: 'September', ed: 157, ef: 4, dd: 5, ls: 15, rd: 9 },
  { name: 'October', ed: 198, ef: 1, dd: 3, ls: 18, rd: 12 }
];

const DEGRADE_LABELS = {
  ed: "EW Degd",
  ef: "EW Fail",
  dd: "DAS Degd",
  ls: "L16",
  rd: "Radar Degd"
}

const PMC_KEYS = {
  pmc: "PMC Aircraft",
  fmc: "FMC Aircraft"
}

function sumData(arr, index) { 
  let name = Number.isInteger(index) ? `Week ${index + 1}` : ALLMOS[arr[0].month - 1];
  const totals = arr.reduce((a, c) => (Object.keys(c).forEach(k => (a[k] = (a[k] || 0) + c[k])), a), {});
  const l = arr.length;
  const result = {
    day: null,
    month: MONTHS[arr[0].month],
    name,
    ed: totals["ed"],
    ef: totals["ef"],
    dd: totals["dd"],
    ls: totals["ls"],
    rd: totals["rd"],
    fa: totals["fa"],
    md: totals["md"],
    rs: totals["rs"],
    sa: totals["sa"],
    sr: totals["sr"]
  }
  return result;
}

let JUN_DATA = sumData(allData.map(v => v.month === 6 && v).filter(v=>v));
let JUL_DATA = sumData(allData.map(v => v.month === 7 && v).filter(v=>v));
let AUG_DATA = sumData(allData.map(v => v.month === 8 && v).filter(v=>v));
let SEP_DATA = sumData(allData.map(v => v.month === 9 && v).filter(v=>v));
let OCT_DATA = sumData(allData.map(v => v.month === 10 && v).filter(v=>v));
let NOV_DATA = sumData(allData.map(v => v.month === 11 && v).filter(v=>v));

JUN_DATA.month = MONTHS[0];
JUN_DATA.name = MOABBR[0];
JUL_DATA.month = MONTHS[1];
JUL_DATA.name = MOABBR[1];
AUG_DATA.month = MONTHS[2];
AUG_DATA.name = MOABBR[2];
SEP_DATA.month = MONTHS[3];
SEP_DATA.name = MOABBR[3];
OCT_DATA.month = MONTHS[4];
OCT_DATA.name = MOABBR[4];
NOV_DATA.month = MONTHS[5];
NOV_DATA.name = MOABBR[5];

const moData = [
  JUN_DATA,
  JUL_DATA,
  AUG_DATA,
  SEP_DATA,
  OCT_DATA,
  NOV_DATA
];

const getPercent = (value, total) => {
  const ratio = total > 0 ? value / total : 0;

  return toPercent(ratio, 2);
};
const toPercent = (decimal, fixed = 0) => `${(decimal * 100).toFixed(fixed)}%`;
const renderTooltipContent = (o) => {
  const { payload, label } = o;
  const total = payload.reduce((result, entry) => (result + entry.value), 0);

  return (
    <div className="customized-tooltip-content">
      <p className="total">{`${label} (Total: ${total})`}</p>
      <ul className="list">
        {
          payload.map((entry, index) => (
            <li key={`item-${index}`} style={{ color: entry.color }}>
              {`${entry.name}: ${entry.value}(${getPercent(entry.value, total)})`}
           </li>
          ))
        }
      </ul>
    </div>
  );
};

const renderSortiesTooltip = (o) => {
  const { payload, label } = o;
  return (
    <div className="sorties-tooltip-content"> 
      <div className="toolTipRow" style={{ marginBottom: "10px", fontWeight: "600" }}>
        <div className="itemA"></div>
        <div className="itemB"></div>
        <div className="itemC">Total</div>
        <div className="itemC"></div>
      </div> 
      {
        payload.map((entry, index) => { 
          const base = entry.dataKey === "sa" ? entry.payload.sr : entry.payload.rs;
          return (
            <div key={`tooltip-${index}`} className="toolTipRow">
              <div className="itemA">
                <div className="toolTipBullet" style={{ border: `2px solid ${entry.color}`, backgroundColor: entry.color }}></div>
              </div>
              <div className="itemB">{SORTIE_LABELS[entry.dataKey]}:</div>
              <div className="itemC">{entry.value}</div>
              <div className="itemC">{(entry.dataKey != "rs") && `${Math.floor((entry.value/base)*100)}%`}</div>
            </div> 
          )
        })
      }
    </div>
  );
};

export default class Dashboard extends PureComponent {
  constructor(props) {
    super(props);

    const newDate = new Date();
    const today = newDate.toDateInputValue();
    const minDate = new Date();
    minDate.setMonth(minDate.getMonth() - 6);
    this.min = minDate.toISOString().slice(0,10);
    this.max = newDate.toISOString().slice(0,10);

    this.state = {
      allData,
      data: moData,
      currentReport: "pmcReport",
      currentMonth: "June",
      currentRange: "6mo",
      filterType: "range",
      rangeFrom: today,
      rangeTo: today,
      currentOutput: "daily",
      dateRange: 0
    };
  }

  handleReportChange(event) {
    const currentReport = event.target.value;
    this.setState({ currentReport });
  }

  handleMonthChange(event) {
    const currentMonth = event.target.value;
    this.setState({ currentMonth });
  }

  handleRangeChange(event) {
    const currentRange = event.target.value;
    let data = [moData[5]];
    switch(currentRange) {
      case "6mo":
        data = moData
        break;
      case "3mo":
        data = [moData[3], moData[4], moData[5]]
        break;
    }
    this.setState({
      currentRange,
      data
    });
  }

  handleFilterTypeChange(event) {
    const filterType = event.target.value;
    if (filterType == "range") { 
      this.setState({ filterType, data: moData })
    } else { this.setState({ filterType }) }
  }

  handleRangeFromChange(event) {
    let rangeFrom = event.target.value;
    // remove timezone consideration with .replace
    const rf = new Date(rangeFrom.replace(/-/g, '\/'));
    const rt = new Date(this.state.rangeTo.replace(/-/g, '\/'));
    const dateRange = Math.floor((rt.getTime() - rf.getTime()) / (1000 * 3600 * 24));
    this.setState({ rangeFrom, dateRange });
  }
  
  handleRangeToChange(event) {
    let rangeTo = event.target.value;
    // remove timezone consideration with .replace
    const rf = new Date(this.state.rangeFrom.replace(/-/g, '\/'));
    const rt = new Date(rangeTo.replace(/-/g, '\/'));
    const dateRange = Math.floor((rt.getTime() - rf.getTime()) / (1000 * 3600 * 24));
    this.setState({ rangeTo, dateRange });
  }

  handleOutputChange(event) {
    let currentOutput = event.target.value;
    this.setState({ currentOutput });
  }

  convertOutputToWeekly(index, dataArr, rangeFrom) {
    let weeklyData = [];
    const rf = new Date(rangeFrom);
    // fd = day of the week for first day of data
    const fd = rf.getUTCDay() + 1;
    // take off first week
    if (dataArr.length > 6) {
      const firstWeek = dataArr.splice(0, 8 - fd);
      weeklyData.push(firstWeek);
      const otherWeeks = dataArr;
      let otherArr = [];
      let lastArr = [];
      const numOfFullWeeks = Math.floor(otherWeeks.length/7);
      let weeksAdded = 0;
      otherWeeks.map((day, i) => {
        const daysLeft = ((otherWeeks.length - 1) - i);
        if (((i+1)%7 === 0)) {
          otherArr = [
            otherWeeks[i-6],
            otherWeeks[i-5],
            otherWeeks[i-4],
            otherWeeks[i-3],
            otherWeeks[i-2],
            otherWeeks[i-1],
            otherWeeks[i]
          ];
          weeklyData.push(otherArr);
          weeksAdded++;
        } else if ((weeksAdded == numOfFullWeeks) && (daysLeft < 7)) {
          lastArr.push(otherWeeks[i])
          if (daysLeft == 0) {
            weeklyData.push(lastArr);
          }
        }
      })
    } else {
      weeklyData = dataArr
    }
    const data = weeklyData.map((arr, i) => (sumData(arr, i)));
    this.setState({ data });
  }

  convertOutputToMonthly(index, dataArr, rangeFrom) {
    const rf = new Date(rangeFrom);
    const moData = groupBy(dataArr, "month");
    const data = moData.map(arr => (sumData(arr))).filter(v => v);
    // let dataWithName = data.map((moData) => {
    //   console.log("moData:", moData);
    //   moData.name = ALLMOS[moData.month - 1];
    //   return moData;
    // });
    this.setState({ data });
  }

  formatDataOutput(index, data) {
    const { currentOutput, rangeFrom } = this.state;
    if (currentOutput === 'weekly') {
      this.convertOutputToWeekly(index, data, rangeFrom)
    } else if (currentOutput === 'monthly') {
      this.convertOutputToMonthly(index, data, rangeFrom)
    }
  }

  updateCustomSearch() {
    let allData = JSON.parse(JSON.stringify(this.state.allData));
    const rf = new Date(this.state.rangeFrom);
    const mo = rf.getMonth() + 1;
    const day = rf.getDate();
    const index = allData.map((o,i) => (o.month == mo && o.day == day && i)).filter(v => v)[0];
    let data = allData.filter((o,i) => ((i > index) && (i < (index + this.state.dateRange + 2))));
    if (this.state.currentOutput === 'daily') {
      data = data.map((dayData) => {
        dayData.name = `${ALLMOS[dayData.month - 1]} ${nth(dayData.day)}`;
        return dayData;
      })
      this.setState({ data });
    } else {
      this.formatDataOutput(index, data);
    }
  } 

  render() {
    const { dateRange } = this.state;

    return (
      <div className="Dashboard">
        <div className="pageTitle no-print"><h4>Flight Data Reports</h4></div>

        <div variant="outlined" className="no-print dashboardDD">
          <div className="ddTitle">
            Select a Report to View
          </div>
          <Select
            labelid="report-select"
            value={this.state.currentReport}
            onChange={this.handleReportChange.bind(this)}
          >
            <MenuItem value="pmcReport">PMC vs FMC</MenuItem>
            <MenuItem value="sortieReport">Event Data</MenuItem>
            <MenuItem value="degradesReport">Degrades Data</MenuItem>
          </Select>
        </div>

        <div variant="outlined" className="no-print dashboardDD rangeOrMonth">
          <div className="ddWrapper">
            <div className="ddTitles">
              <div className="ddTitle">
                <span className="ddTitleText">
                  Select View
                </span>
                <RadioGroup className="tmtRadios" aria-label="position" value={this.state.filterType} onChange={this.handleFilterTypeChange.bind(this)}>
                  <FormControlLabel
                    value="range"
                    control={<Radio color="primary" />}
                  />
                </RadioGroup>
              </div>
              <div className="ddTitle">
                <span className="ddTitleText">
                  Or Custom Range
                </span>
                <RadioGroup className="tmtRadios" aria-label="position" value={this.state.filterType} onChange={this.handleFilterTypeChange.bind(this)}>
                  <FormControlLabel
                    value="customRange"
                    control={<Radio color="primary" />}
                  />
                </RadioGroup>
              </div>
            </div>
            {this.state.filterType === "range" && <div className="ddContainer">
              <Select
                labelid="report-select"
                value={this.state.currentRange}
                onChange={this.handleRangeChange.bind(this)}
              >
                <MenuItem value="1mo">1 Month</MenuItem>
                <MenuItem value="3mo">3 Months</MenuItem>
                <MenuItem value="6mo">6 Months</MenuItem>
              </Select>
            </div>}
            {this.state.filterType === "customRange" && 
              <div className="ddContainer ddSection">
                <div className="inputsContainer">
                  <div className="dRSWrapper">
                    <div className="dateRangeSection">
                      <h4 className="dateTitle">From:</h4>
                      <input id="date" value={this.state.rangeFrom} onChange={this.handleRangeFromChange.bind(this)} type="date" min={this.min} max={this.state.rangeTo}/>
                      <h4 className="dateTitle">To:</h4>
                      <input id="date" value={this.state.rangeTo} onChange={this.handleRangeToChange.bind(this)} type="date" min={this.state.rangeFrom} max={this.max}/>
                    </div>
                  </div>
                  {dateRange >= 14 && 
                    <div>
                      <div className="ddContainer outputSelect">
                        Select Output
                        <Select
                          labelid="report-select"
                          value={this.state.currentOutput}
                          onChange={this.handleOutputChange.bind(this)}
                        >
                          <MenuItem value="daily">Daily</MenuItem>
                          <MenuItem value="weekly">Weekly</MenuItem>
                          {dateRange >= 60 && <MenuItem value="monthly">Monthly</MenuItem>}
                        </Select>
                      </div>
                    </div>
                  }
                </div>
                <div>
                  <Button className="updateBtn" size="small" variant="outlined" color="inherit" onClick={this.updateCustomSearch.bind(this)}>Update</Button>
                </div>
              </div>
            }
          </div>
        </div>

        <div className="dashboardContainer">
          {this.state.currentReport == "sortieReport" && 
            <div>
              <div className="reportTitle printThis">
                Sorties Report
              </div>

              <LineChart
                width={500}
                height={300}
                data={this.state.data}
                margin={{
                  top: 5, right: 30, left: 20, bottom: 5,
                }}
              >
                <CartesianGrid strokeDasharray="3 3" />
                <XAxis dataKey="name" />
                <YAxis />
                <Tooltip content={renderSortiesTooltip} wrapperStyle={toolTipStyles} />
                <Legend />
                <Line type="monotone" dataKey="sr" name={SORTIE_KEYS["sr"]} stroke="#E53D00" activeDot={{ r: 8 }} />
                <Line type="monotone" dataKey="sa" name={SORTIE_KEYS["sa"]} stroke="#A13FBF" activeDot={{ r: 8 }} />
              </LineChart>

            </div>
          }

          {this.state.currentReport == "pmcReport" && <div>
            <div className="reportTitle printThis">
              PMC Vs. FMC
            </div>

            <BarChart
              width={500}
              height={300}
              data={this.state.data}
              margin={{
                top: 20, right: 30, left: 20, bottom: 5,
              }}
            >
              <CartesianGrid strokeDasharray="3 3" />
              <XAxis dataKey="name" />
              <YAxis />
              <Tooltip />
              <Legend />
              <Bar dataKey="md" name="PMC" stackId="a" fill="#21A0A0" />
              <Bar dataKey="fa" name="FMC" stackId="a" fill="#3AA9FF" />
            </BarChart>

          </div>}

          {this.state.currentReport == "degradesReport" && 
            <div>

              <div className="reportTitle printThis">
                Degrades Report
              </div>

              <BarChart width={730} height={250} data={this.state.data}>
                <CartesianGrid strokeDasharray="3 3" />
                <XAxis dataKey="name" />
                <YAxis />
                <Tooltip content={renderTooltipContent} wrapperStyle={toolTipStyles} />
                <Legend />
                <Bar dataKey="ed" name={DEGRADE_LABELS["ed"]} fill="#21A0A0" />
                <Bar dataKey="ef" name={DEGRADE_LABELS["ef"]} fill="#3AA9FF" />
                <Bar dataKey="dd" name={DEGRADE_LABELS["dd"]} fill="#FFE900" />
                <Bar dataKey="ls" name={DEGRADE_LABELS["ls"]} fill="#E53D00" />
                <Bar dataKey="rd" name={DEGRADE_LABELS["rd"]} fill="#A13FBF" />
              </BarChart>

            </div>
          }
        </div>
        <div className="reportFooter">
          <div className="printBtn no-print">
            <Button onClick={() => window.print()} size="large" variant="outlined" color="inherit">Print Report</Button>
          </div>
        </div>
      </div>
    );
  }
}