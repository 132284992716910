import React, { Component } from "react";
import MenuItem from '@material-ui/core/MenuItem';
import OutlinedInput from '@material-ui/core/OutlinedInput';
import Select from '@material-ui/core/Select';
import InputLabel from '@material-ui/core/InputLabel';
import FormControl from '@material-ui/core/FormControl';
import ReactTooltip from 'react-tooltip';
import IconButton from '@material-ui/core/IconButton';
import SearchIcon from '@material-ui/icons/Search';
import Checkbox from '@material-ui/core/Checkbox';
import Paper from '@material-ui/core/Paper';
import InputBase from '@material-ui/core/InputBase';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import "./Reports.css";

function parseQuery(queryString) {
    var query = {};
    var pairs = (queryString[0] === '?' ? queryString.substr(1) : queryString).split('&');
    for (var i = 0; i < pairs.length; i++) {
        var pair = pairs[i].split('=');
        query[decodeURIComponent(pair[0])] = decodeURIComponent(pair[1] || '');
    }
    return query;
}

const types = {
  or: "Occurrence Reports",
  ar: "Action Requests",
  hr: "Hazard Reports",
  mr: "Mishap Reports",
  all: "All Reports"
}

const planeKeys = {
  a: "f35com",
  b: "f35a",
  c: "f35b",
  d: "f35c"
}

const planes = {
  a: "F-35 Common",
  b: "F-35A",
  c: "F-35B",
  d: "F-35C"
}

const variants = {
  f35com: "F-35 Common",
  f35a: "F-35A",
  f35b: "F-35B",
  f35c: "F-35C",
  all: "All"
}

export default class Reports extends Component {
  constructor(props) {
    super(props);

    this.searchQueryInput = React.createRef();
    this.planeKeys = planeKeys;
    const reports = this.props.data.filter(d=>{return (d.content.dataType === "report") }).sort((a, b) => { 
      return  new Date(a.createdAt) - new Date(b.createdAt)
    });
    const search = parseQuery(this.props.location.search);
    const pageTitle = (search.type && search.t) ? `${types[search.type]} -- ${planes[search.t]}` : '';

    const homeQuery = search.hq;
    let data = {
      reports,
      type: search.type || 'all',
      year: '20',
      month: 'jun',
      aircraft: search.aircraft || 'all',
      searchQuery: ''
    };
    let searchAllDates = false;

    if (homeQuery) {
      searchAllDates = true;
      data = {
        reports,
        type: 'all',
        year: '20',
        month: 'jun',
        aircraft: 'all',
        pageLoaded: false,
        searchQuery: homeQuery
      };
    }

    this.state = {
      pageTitle,
      search,
      searchAllDates,
      data,
      parentNavClicks: this.props.parentNavClicks
    };
  }

  async componentDidMount() {
    await this.updateDocs();
    this.setState({
      pageLoaded: true
    })
  }

  async componentWillReceiveProps(a) {
    const { aircraftState } = this.state.data;
    const params = parseQuery(a.location.search);
    let aircraft = params.t;
    if (a.subLink && a.subLink.length > 0 && (aircraft === aircraftState) && aircraft !== a.subLink) {
      aircraft = a.subLink;
    }
    const pageTitle = `${types[params.type]} -- ${planes[aircraft]}`;

    if (a.parentNavClicks !== this.state.parentNavClicks) {
      await this.setState({
        pageTitle,
        parentNavClicks: a.parentNavClicks,
        search: params,
        data: {
          ...this.state.data,
          type: params.type,
          aircraft: this.planeKeys[aircraft]
        }
      })
      this.updateDocs();
    }
  }

  parseDataKey(dataKey) {
    const [re, type, year, month, aircraft] = dataKey.split('-');
    return { type, year, month, aircraft, re };
  }

  updateDocs = async() => {
    const search = this.state.search;
    const { type, year, month, aircraft, searchQuery } = this.state.data;
    const reports = this.props.data.filter(d=>{
      if (!d.content.dataKey) { return false };
      const keys = this.parseDataKey(d.content.dataKey);
      const text = `${d.content.title.toLowerCase()} ${d.content.docCode.toLowerCase()} ${d.content.description.toLowerCase()}`;
      let acMatch = false;
      let typeMatch = false;
      let dateMatch = false;
      if (this.state.searchAllDates) {
        dateMatch = true;
      } else {
        dateMatch = (keys.year === year) && (keys.month === month)
      }
      if (aircraft === 'all') {
        acMatch = true
      } else {
        const x = (keys.aircraft === "f35a" || keys.aircraft === "f35com");
        const y = (aircraft === "f35a" || aircraft === "f35com");
        acMatch = (keys.aircraft === aircraft || (x && y))
      }
      if (type === 'all') {
        typeMatch = true
      } else {
        typeMatch = (keys.type === type)
      }
      return ((searchQuery.length === 0) || (text.includes(searchQuery.toLowerCase()))) && typeMatch && dateMatch && acMatch;
    });

    let variant = variants[aircraft];
    if (search.aircraft === "f35com" && search.t === "a") {
      variant = planes[search.t];
    }
    
    const pageTitle = `${types[type]} -- ${variant}`;
    await this.setState({
      pageTitle,
      data: {
        ...this.state.data,
        reports,
      }
    });
  }

  handleSearchChange = async (key, e) => {
    const val = e.target.value.toString();
    await this.setState({
      data: {
        ...this.state.data,
        [key]: val
      }
    });
    await this.updateDocs();
  }


  handleSearchQueryChange = async () => {
    const searchQuery = this.searchQueryInput.current.lastChild.value;
    await this.setState({
      data: {
        ...this.state.data,
        searchQuery
      }
    });
    await this.updateDocs();
  }

  handleSearchKeyDown = (e) => {
    if(e.keyCode === 13){
      this.handleSearchQueryChange();
    }
  }

  handleSearchAllDatesChange = async (e) => {
    await this.setState({
      searchAllDates: !this.state.searchAllDates
    });
    await this.updateDocs();
  }

  renderSafetyReports() {
    const reports = this.state.data.reports;

    if (reports.length === 0) {
      return (
        <div className="safetyReportsContainer">
          <div className="srNoContent"> No content available </div>
        </div>
      );
    } else {
      if (reports.length > 19) {
        const listA = reports.slice(0, reports.length/2 - 1);
        const listB = reports.slice(reports.length/2, reports.length - 1);
        return (
          <div className="safetyReportsContainer columns">
            <div className="reportCol">
              {listA.map((data, i) => {
                const { docCode, title, description } = data.content;

                return (
                  <div key={`report-left-${i}`} className={`srTooltipWrapper ${listA.length === (i+1) ? 'lastEl' : ''}`}>
                    {title.includes('~') && (
                      <div className="srLink" key={`srItem-left-${i}`} data-tip data-for={`srItem-left-${i}`}>{docCode}
                        <div className="linkParts">
                          {title.split('~').map((part, j) => (
                             <div className="linkPart" key={`part-left-${j}`}>{part}</div>
                          ))}
                        </div>
                      </div>
                    )}
                    {!title.includes('~') && (
                      <div className="srLink" key={`srItem-left-${i}`} data-tip data-for={`srItem-left-${i}`}>{docCode} - {title}</div>
                    )}
                    <ReactTooltip
                      className="srTooltip"
                      place="right"
                      id={`srItem-left-${i}`}
                      type="info"
                      effect="solid"
                    >
                      <span>{description}</span>
                    </ReactTooltip>
                  </div>
                )
              })}
            </div>

            <div className="multiColDivider"></div>

            <div className="reportCol">
              {listB.map((data, i) => {
                const { docCode, title, description } = data.content;

                return (
                  <div key={`report-right-${i}`} className={`srTooltipWrapper ${listB.length === (i+1) ? 'lastEl' : ''}`}>
                    {title.includes('~') && (
                      <div className="srLink" key={`srItem-right-${i}`} data-tip data-for={`srItem-right-${i}`}>{docCode}
                        <div className="linkParts">
                          {title.split('~').map((part, j) => (
                             <div className="linkPart" key={`part-right-${j}`}>{part}</div>
                          ))}
                        </div>
                      </div>
                    )}
                    {!title.includes('~') && (
                      <div className="srLink" key={`srItem-right-${i}`} data-tip data-for={`srItem-right-${i}`}>{docCode} - {title}</div>
                    )}
                    <ReactTooltip
                      className="srTooltip"
                      place="left"
                      id={`srItem-right-${i}`}
                      type="info"
                      effect="solid"
                    >
                      <span>{description}</span>
                    </ReactTooltip>
                  </div>
                )
              })}
            </div>
          </div>
        );
      } else {
        return (
          <div className="safetyReportsContainer singleCol">
            {reports.map((data, i) => {
              const { docCode, title, description } = data.content;

              return (
                <div key={`report-${i}`} className={`srTooltipWrapper ${reports.length === (i+1) ? 'lastEl' : ''}`}>
                  {title.includes('~') && (
                    <div className="srLink" key={`srItem-${i}`} data-tip data-for={`srItem-${i}`}>{docCode}
                      <div className="linkParts">
                        {title.split('~').map((part, q) => (
                           <div className="linkPart" key={`part-${q}`}>{part}</div>
                        ))}
                      </div>
                    </div>
                  )}
                  {!title.includes('~') && (
                    <div className="srLink" key={`srItem-${i}`} data-tip data-for={`srItem-${i}`}>{docCode} - {title}</div>
                  )}

                  <ReactTooltip
                    className="srTooltip"
                    place="right"
                    id={`srItem-${i}`}
                    type="info"
                    effect="solid"
                  >
                    <span>{description}</span>
                  </ReactTooltip>
                </div>
              )
            })}
          </div>
        );
      }
    }
  }

  render() {
    return (
      <div className="Reports">
        <div className="pageTitle">{this.state.pageTitle}</div>

        <div className="topBar">
          <div className="searchContainer">
            <div className="search">
              <Paper>
                <InputBase
                  ref={this.searchQueryInput}
                  placeholder={this.state.data.searchQuery || 'Search'}
                  onKeyDown={this.handleSearchKeyDown}
                  inputProps={{ 'aria-label': 'search' }}
                />
                <IconButton onClick={this.handleSearchQueryChange} aria-label="search">
                  <SearchIcon />
                </IconButton>
              </Paper>
            </div>

            <div className="dropdown">
              <FormControl variant="outlined">
                <InputLabel htmlFor="outlined-aircraft">
                  Variant
                </InputLabel>
                <Select
                  value={this.state.data.aircraft}
                  input={<OutlinedInput labelWidth={40} name="searchVariant" id="outlined-aircraft" />}
                  onChange={this.handleSearchChange.bind(this, 'aircraft')}
                >
                  {Object.keys(variants).map((key, i) => (
                    <MenuItem key={`variant-${i}`} value={key}>{variants[key]}</MenuItem>
                  ))}
                </Select>
              </FormControl>
            </div>

            <div className="dropdown">
              <FormControl variant="outlined" className="typeDropdown">
                <InputLabel htmlFor="outlined-docType">
                  Document Type
                </InputLabel>
                <Select
                  value={this.state.data.type}
                  input={<OutlinedInput labelWidth={90} name="searchVariant" id="outlined-docType" />}
                  onChange={this.handleSearchChange.bind(this, 'type')}
                >
                  {Object.keys(types).map((key, i) => (
                    <MenuItem key={`type-${i}`} value={key}>{types[key]}</MenuItem>
                  ))}
                </Select>
              </FormControl>
            </div>


            <div className="dropdown">
              <FormControl variant="outlined">
                <InputLabel htmlFor="outlined-year">
                  Year
                </InputLabel>
                <Select
                  disabled={this.state.searchAllDates}
                  value={parseInt(this.state.data.year, 10)}
                  onChange={this.handleSearchChange.bind(this, 'year')}
                  input={<OutlinedInput labelWidth={40} name="year" id="outlined-year" />}
                >
                  <MenuItem value={18}>2018</MenuItem>
                  <MenuItem value={19}>2019</MenuItem>
                  <MenuItem value={20}>2020</MenuItem>
                  <MenuItem value={21}>2021</MenuItem>
                  <MenuItem value={22}>2022</MenuItem>
                </Select>
              </FormControl>
            </div>
            <div className="dropdown">
              <FormControl variant="outlined">
                <InputLabel htmlFor="outlined-aircraft">
                  Month
                </InputLabel>
                <Select
                  disabled={this.state.searchAllDates}
                  value={this.state.data.month}
                  onChange={this.handleSearchChange.bind(this, 'month')}
                  id="month"
                  input={<OutlinedInput labelWidth={40} name="month" id="outlined-aircraft" />}
                >
                  <MenuItem value='jan'>JAN</MenuItem>
                  <MenuItem value='feb'>FEB</MenuItem>
                  <MenuItem value='mar'>MAR</MenuItem>
                  <MenuItem value='apr'>APR</MenuItem>
                  <MenuItem value='may'>MAY</MenuItem>
                  <MenuItem value='jun'>JUN</MenuItem>
                  <MenuItem value='jul'>JUL</MenuItem>
                  <MenuItem value='aug'>AUG</MenuItem>
                  <MenuItem value='sep'>SEP</MenuItem>
                  <MenuItem value='oct'>OCT</MenuItem>
                  <MenuItem value='nov'>NOV</MenuItem>
                  <MenuItem value='dec'>DEC</MenuItem>
                </Select>
              </FormControl>
            </div>

            <div className="dropdown">
              <FormControlLabel
                control={
                  <Checkbox
                    checked={this.state.searchAllDates}
                    onChange={this.handleSearchAllDatesChange}
                    color="primary"
                    inputProps={{
                      'aria-label': 'Search All Dates',
                    }}
                  />
                }
                labelPlacement="top"
                label="Search All Dates"
              />
            </div>

          </div>

        </div>

        <div>
          {this.renderSafetyReports()}
        </div>
      </div>
    );
  }
}
