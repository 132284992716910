import React, { Component } from "react";
import FormControlLabel from '@material-ui/core/FormControlLabel';
import Cookies from 'universal-cookie';
import Radio from '@material-ui/core/Radio';
import Button from '@material-ui/core/Button';
import RadioGroup from '@material-ui/core/RadioGroup';
import TextField from '@material-ui/core/TextField';
import FormLabel from '@material-ui/core/FormLabel';
import Checkbox from '@material-ui/core/Checkbox';
import Modal from 'react-modal';
import EditIcon from '@material-ui/icons/Edit';
import DeleteIcon from '@material-ui/icons/Delete';
import IconButton from '@material-ui/core/IconButton';
import TimeSelect from '../components/TimeSelect';
import Alert from '@material-ui/lab/Alert';
import Select from 'react-select';
import "./NewPFD.css";
import { API } from "aws-amplify";


Date.prototype.toDateInputValue = (function() {
    const local = new Date(this);
    local.setMinutes(this.getMinutes() - this.getTimezoneOffset());
    return local.toJSON().slice(0,10);
});

const cookies = new Cookies();

const Bool = (str) => {
  switch(str) {
    case "Yes":
      return true
    case "No":
      return false
    default:
      return false
  }
}
const modalStyles = {
  content : {
    top                   : '50%',
    left                  : '50%',
    right                 : 'auto',
    width                 : '300px',
    bottom                : 'auto',
    marginRight           : '-50%',
    transform             : 'translate(-50%, -50%)'
  }
};
const QUESTIONS = [
  {
    q: "How many F35's were scheduled for your event?",
    t: "multi",
    c: ['1','2','3','4'],
    k: "numberOfAircraft"
  },
  {
    q: "Were you the flight lead?",
    t: "bool",
    c: ["Yes", "No"],
    k: "flightLeadForEvent"
  },
  {
    q: "Did an on-deck aircraft fallout require the use of a spare aircraft?",
    t: "bool",
    c: ["Yes", "No"],
    k: "wereSparesRequired"
  },
  {
    q: "How many spare aircraft were needed?",
    t: "multi",
    c: ['1','2','3'],
    k: "howManyRequired"
  },
  {
    q: "How many spare aircraft were available?",
    t: "multi",
    c: ['0','1','2','3'],
    k: "howManyAvailable"
  }
];

const CODES = {
  ew: {
    label: "EW",
    deg: false,
    fail: false,
    quad: [0,0,0,0],
    desc: ""
  },
  das: {
    label: "DAS",
    deg: false,
    fail: false,
    quad: [0,0,0,0,0,0],
    desc: ""
  },
  radar: {
    label: "Radar",
    deg: false,
    fail: false,
    desc: ""
  },
  comms: {
    label: "CNI",
    deg: false,
    fail: false,
    desc: ""
  },
  nav: {
    label: "Nav",
    deg: false,
    fail: false,
    desc: ""
  },
  iff: {
    label: "IFF",
    deg: false,
    fail: false,
    desc: ""
  },
  intgtr: {
    label: "INTG",
    deg: false,
    fail: false,
    desc: ""
  },
  eots: {
    label: "EOTS",
    deg: false,
    fail: false,
    desc: ""
  },
  l16: {
    label: "L16",
    deg: false,
    fail: false,
    desc: ""
  },
  madl: {
    label: "MADL",
    deg: false,
    fail: false,
    desc: ""
  },
  sms: {
    label: "SMS",
    deg: false,
    fail: false,
    desc: ""
  },
  cm: {
    label: "CM",
    deg: false,
    fail: false,
    desc: ""
  }
};


const STAGES = ["numberOfAircraft", "flightLeadForEvent", "wereSparesRequired", "howManyRequired", "howManyAvailable"];
const createPFD = () => {
  const today = new Date().toDateInputValue();
  // quad = [0,0,0,0] = [fl, fr, al, ar]
  const pfd = {
    eNum: null,
    degrades: false,
    degradesAffectMission: null,
    numberOfAircraft: 1,
    wereSparesRequired: false,
    howManyRequired: 0,
    tacticalMissionType: null,
    howManyAvailable: 0,
    flightLeadForEvent: false,
    squadron: "VFA-125",
    aircraft: "",
    date: today,
    codes: CODES
  };
  return pfd;
}

const INITIAL_ICAWS = [
  { label: 'FCS ALIGN TNS' },
  { label: 'FCS CG DEGD' },
  { label: 'FCS CH FAIL A' },
  { label: 'FCS CH FAIL B' },
  { label: 'FCS CH FAIL C' },
  { label: 'FCS CH HOT A' },
  { label: 'FCS CH HOT B' },
  { label: 'FCS CH HOT C' },
  { label: 'FCS DATA FAIL' },
  { label: 'FCS DEGD' },
  { label: 'FCS EHA COLD' },
  { label: 'FCS EHA HOT' }
];

// green:  #25b03d
// yellow: #e1e10a
// orange: #e33a10
// black:  #000202
// white:  #ebebeb

const ICAW_HEX_BG = {
  'FCS ALIGN TNS': '#000202',
  'FCS CG DEGD': '#e1e10a',
  'FCS CH FAIL A': '#e1e10a',
  'FCS CH FAIL B': '#e1e10a',
  'FCS CH FAIL C': '#e1e10a',
  'FCS CH HOT A': '#e1e10a',
  'FCS CH HOT B': '#e1e10a',
  'FCS CH HOT C': '#e1e10a',
  'FCS DATA FAIL': '#e33a10',
  'FCS DEGD': '#e1e10a',
  'FCS EHA COLD': '#e1e10a',
  'FCS EHA HOT': '#e1e10a'
}

const ICAW_HEX_COLOR = {
  'FCS ALIGN TNS': '#25b03d',
  'FCS CG DEGD': '#000202',
  'FCS CH FAIL A': '#000202',
  'FCS CH FAIL B': '#000202',
  'FCS CH FAIL C': '#000202',
  'FCS CH HOT A': '#000202',
  'FCS CH HOT B': '#000202',
  'FCS CH HOT C': '#000202',
  'FCS DATA FAIL': '#ebebeb',
  'FCS DEGD': '#000202',
  'FCS EHA COLD': '#000202',
  'FCS EHA HOT': '#000202'
}

let initialState = {
  showAlert: false,
  takeOffTime: 0,
  discFormShown: false,
  discrepancies: [],
  showOccModal: false,
  showVSInput: false,
  showCIInput: false,
  showICAWOptions: false,
  ICAWS: INITIAL_ICAWS,
  inDiscEditMode: false,
  landTime: 0,
  currentICAW: "",
  currentDesc: "",
  assocICAW: true,
  extraDiscrepancies: [],
  submitted: false,
  discIdBeingEdited: null,
  tot: "",
  otherQuestions: [
    {
      question: "Start-up Cold Iron required?",
      key: "coldIronRequired",
      selected: null
    },
    {
      question: "Post-flight VS-Bit?",
      key: "postFlightVSBit",
      selected: null
    },
    {
      question: "",
      selected: null 
    },
    {
      question: "Did you have any Aircraft System issues that led to an In-Flight Mission Abort?",
      key: "issuesLedToAbort",
      selected: null 
    }
  ],
  initialQuestions: [null, null, null, null, null],
  pfd: {
    numberOfAircraft: 1,
    flightLeadForEvent: false,
    wereSparesRequired: false,
    howManyRequired: 1,
    howManyAvailable: 1,
    degrades: false,
    degradesAffectMission: null,
    aircraft: "",
    date: "",
    squadron: "VFA-125",
    tacticalMissionType: "A/A",
    codes: {
      ew: '',
      das: ''
    }
  }
};

export default class NewPFD extends Component {
  constructor(props) {
    super(props);

    this.state = initialState;
    this.pageRef = React.createRef();
  }

  componentWillMount() {
    Modal.setAppElement('body');
    const pfd = createPFD();
    this.setState({ pfd });
  }

  updatePFDCheckbox = event => {
    let checked = event.target.checked;
    let id = event.target.id;
    const pfdState = JSON.parse(JSON.stringify(this.state.pfd));
    this.setState({
      pfd: {
        ...pfdState,
        [id]: checked
      }
    });
  }

  hideAlert = () => {
    this.setState({
      showAlert: false
    })
  }
 
  updatePFD = event => {
    let val = event.target.value;
    let id = event.target.id;
    let name = event.target.name;

    // id example (three types) = squadron || code-das || code-ew-quad
    const pfdState = JSON.parse(JSON.stringify(this.state.pfd));
    if (name && name === 'tacticalMissionType') {
      this.setState({
        pfd: {
          ...pfdState,
          tacticalMissionType: val
        }
      });
    } else if (id.includes("code")) {
      const codes = JSON.parse(JSON.stringify(this.state.pfd.codes));
      const codeKey = id.split("-")[1].toLowerCase();
      let quad = codes[codeKey].quad;
      // ew-code value needs to be updated
      //
      // id example = code-ew-deg || code-sms-desc
      const subKey = id.split("-")[2];
      if ((subKey === 'deg') || (subKey === 'fail')) {
        // this is a checkbox
        val = event.target.checked;
        if (!val && ((codeKey === "ew") || (codeKey === "das"))) {
          // got false value for one, so check the other
          const keyToCheck = subKey === 'deg' ? 'fail' : 'deg';
          if (!codes[codeKey][keyToCheck]) {
            // deg && fail are false, then reset quad
            if (codeKey === "ew") {
              quad = [0,0,0,0];
            } else {
              quad = [0,0,0,0,0,0];
            }
          } else {
            // val (fail or degd) is false now so flip
            // all "val" values in quad to zero
            const vi = subKey === 'deg' ? 1 : 2;
            quad = quad.map(v => {
              return v === vi ? 0 : v;
            })
          }
        }
      }
      const codeObj = JSON.parse(JSON.stringify(this.state.pfd.codes[codeKey]));
      this.setState({
        pfd: {
          ...pfdState,
          codes: {
            ...codes,
            [codeKey]: {
              ...codeObj,
              [subKey]: val,
              quad
            }
          }
        }
      });
    } else {
      // non-code value needs to be updated
      //
      // id example = squadron || aircraft
      if (id === "eNum" && val.toString().length > 2) {
        return false
      }
      this.setState({
        pfd: {
          ...pfdState,
          [id]: val
        }
      });
    }
  }

  updateMSD(bool) {
    const degrades = (bool === 'YES');
    const pfdState = JSON.parse(JSON.stringify(this.state.pfd));
    if (!degrades) {
      const newPfdState = JSON.parse(JSON.stringify(this.state.pfd));
      newPfdState.codes = CODES;
      this.setState({
        pfd: {
          ...newPfdState,
          degrades
        }
      });
    } else {
      this.setState({ 
        pfd: {
          ...pfdState,
          degrades
        }
      });
    }
  }

  updateDAM(bool) {
    const degradesAffectMission = (bool === 'YES');
    const pfdState = JSON.parse(JSON.stringify(this.state.pfd));
    this.setState({ 
      pfd: {
        ...pfdState,
        degradesAffectMission
      }
    });
  }

  showDiscForm() {
    this.setState({
      discFormShown: true
    })
  }

  hideDiscForm() {
    this.setState({
      discFormShown: false,
      assocICAW: true,
      currentICAW: "",
      currentDesc: "",
      discIdBeingEdited: null,
      inDiscEditMode: false
    })
  }

  closeModal = () => {
    // todo: undo, the submit undo
    this.setState({ showOccModal: false, submitted: false });
  }

  renderModal() {
    return (
      <Modal
        style={modalStyles}
        isOpen={this.state.showOccModal && this.state.submitted}
        contentLabel="Example Modal"
      >
        <div>
          <div>
            <h5>Would you like to create an Occurence Report?</h5>
          </div>
          <div className="modalButtons">
            <Button onClick={this.closeModal} size="medium" variant="outlined" color="inherit">Not now</Button>
            <Button href="/data/new" size="medium" variant="outlined" color="primary">Yes</Button>
          </div>
        </div>
      </Modal>
    );
  }

  updateQuad(quadIndex, key, degradeCode) {
    // quad ex. [0,0,0,1,2]
    // degradeCode can be 0, 1 or 2
    // 0 = no degrade or fail
    // 1 = degrade --- ( except for das. For das degd & fail are 1 -- for now )
    // 2 = fail
    const pfdState = JSON.parse(JSON.stringify(this.state.pfd));
    const codes = JSON.parse(JSON.stringify(this.state.pfd.codes));
    const codeObj = JSON.parse(JSON.stringify(this.state.pfd.codes[key]));
    const quad = JSON.parse(JSON.stringify(this.state.pfd.codes[key].quad));
    // I need a way to reset the quadrant to no degrade or fail,
    // so if I click on yellow and it's already yellow then reset.
    quad[quadIndex] = quad[quadIndex] === degradeCode ? 0 : degradeCode;
    const pfd = {
      ...pfdState,
      codes: {
        ...codes,
        [key]: {
          ...codeObj,
          quad
        }
      }
    };
    this.setState({ pfd });
  }

  renderCodeRow(code, index) {
    const key = code.toLowerCase();
    const ew = key === "ew";
    const das = key === "das";
    const label = this.state.pfd.codes[key].label;
    const QC = ['', 'selectedDegrade', 'selectedFail']; // quad classes
    if (ew) {
      const { deg, fail, label } = this.state.pfd.codes.ew;
      return (
        <div className="optContainer ew" key={`${code}-#{key}-row`}>
          <label className="codeItem">{label}</label>
          <input className="codeItem cbItem" onChange={this.updatePFD} type="checkbox" id={`code-${key}-deg`} />
          <input className="codeItem cbItem" onChange={this.updatePFD} type="checkbox" id={`code-${key}-fail`} />
          <TextField
            id={`code-${key}-desc`}
            className="codeItem"
            value={this.state.pfd.codes[key].desc}
            onChange={this.updatePFD}
            margin="dense"
            variant="outlined"
            multiline
            rowsMax="7"
          />

          {/* If a degrade reported for ew but no failure, then only show the degrade quadrant selector (yellow) */}
          {deg && !fail && <div className="degSelector ewSelector">
            <div className="dsTitle"><h5>EW <span className="bgYellow">Degrade</span> Quadrants</h5></div>
            <div className="dsInner">
              <img className="quadTarget" src="/target.svg"/>
              <div onClick={this.updateQuad.bind(this, 0, key, 1)} className={`quarter left-top ${this.state.pfd.codes[key].quad[0] === 1 ? 'selectedDegrade' : ''}`}></div>
              <div onClick={this.updateQuad.bind(this, 1, key, 1)} className={`quarter right-top ${this.state.pfd.codes[key].quad[1] === 1 ? 'selectedDegrade' : ''}`}></div>
              <div onClick={this.updateQuad.bind(this, 2, key, 1)} className={`quarter left-bottom ${this.state.pfd.codes[key].quad[2] === 1 ? 'selectedDegrade' : ''}`}></div>
              <div onClick={this.updateQuad.bind(this, 3, key, 1)} className={`quarter right-bottom ${this.state.pfd.codes[key].quad[3] === 1 ? 'selectedDegrade' : ''}`}></div>
            </div>
          </div>}

        {/* If a failure reported for ew but no degrade, then only show the failure quadrant selector (red) */}
          {fail && !deg && <div className="degSelector ewSelector">
            <div className="dsTitle"><h5>EW <span className="bgRed">Fail</span> Quadrants</h5></div>
            <div className="dsInner">
              <img className="quadTarget" src="/target.svg"/>
              <div onClick={this.updateQuad.bind(this, 0, key, 2)} className={`quarter left-top ${this.state.pfd.codes[key].quad[0] === 2 ? 'selectedFail' : ''}`}></div>
              <div onClick={this.updateQuad.bind(this, 1, key, 2)} className={`quarter right-top ${this.state.pfd.codes[key].quad[1] === 2 ? 'selectedFail' : ''}`}></div>
              <div onClick={this.updateQuad.bind(this, 2, key, 2)} className={`quarter left-bottom ${this.state.pfd.codes[key].quad[2] === 2 ? 'selectedFail' : ''}`}></div>
              <div onClick={this.updateQuad.bind(this, 3, key, 2)} className={`quarter right-bottom ${this.state.pfd.codes[key].quad[3] === 2 ? 'selectedFail' : ''}`}></div>
            </div>
          </div>}

          {/* If a degrade and a failure reported for ew, then show the multigrade quadrant selector */}
          {deg && fail && <div className="degSelector ewSelector">
            <div className="dsTitle"><h5>EW <span className="bgYellow">Degrade</span> / <span className="bgRed">Fail</span> Quadrants</h5></div>
            <div className="dsInner">
              <img className="quadTarget" src="/target.svg"/>
              <div className={`quarter left-top ${QC[this.state.pfd.codes[key].quad[0]]}`}>
                <div onClick={this.updateQuad.bind(this, 0, key, 1)} className="halfQuarter left"></div>
                <div onClick={this.updateQuad.bind(this, 0, key, 2)} className="halfQuarter right"></div>
              </div>
              <div className={`quarter right-top ${QC[this.state.pfd.codes[key].quad[1]]}`}>
                <div onClick={this.updateQuad.bind(this, 1, key, 1)} className="halfQuarter left"></div>
                <div onClick={this.updateQuad.bind(this, 1, key, 2)} className="halfQuarter right"></div>
              </div>
              <div className={`quarter left-bottom ${QC[this.state.pfd.codes[key].quad[2]]}`}>
                <div onClick={this.updateQuad.bind(this, 2, key, 1)} className="halfQuarter left"></div>
                <div onClick={this.updateQuad.bind(this, 2, key, 2)} className="halfQuarter right"></div>
              </div>
              <div className={`quarter right-bottom ${QC[this.state.pfd.codes[key].quad[3]]}`}>
                <div onClick={this.updateQuad.bind(this, 3, key, 1)} className="halfQuarter left"></div>
                <div onClick={this.updateQuad.bind(this, 3, key, 2)} className="halfQuarter right"></div>
              </div>
            </div>
          </div>}

        </div>
      );
    } else if (das) {
      const dasD = this.state.pfd.codes.das.deg;
      const dasF = this.state.pfd.codes.das.fail;
      return (
        <div className="optContainer" key={`${code}-row`}>
          <label className="codeItem">{label}</label>
          <input className="codeItem cbItem" onChange={this.updatePFD} type="checkbox" id={`code-${key}-deg`} />
          <input className="codeItem cbItem" onChange={this.updatePFD} type="checkbox" id={`code-${key}-fail`} />
          <TextField
            id={`code-${key}-desc`}
            className="codeItem"
            onChange={this.updatePFD}
            value={this.state.pfd.codes[key].desc}
            margin="dense"
            variant="outlined"
            multiline
            rowsMax="7"
          />
          {(dasD || dasF) && <div className="dasChoicesContainer">
            {das && dasD && <div className="dasDegradesContainer">
                <div className="dsTitle"><h5>DAS Camera <span className="bgYellow">Degrades</span></h5></div>
                <div className="dsInner">
                  <div className="dasChoices">
                    <div className={`dasChoice ${this.state.pfd.codes[key].quad[0] === 1 ? 'bgYellow' : ''}`} onClick={this.updateQuad.bind(this, 0, key, 1)}>BA</div>
                    <div className={`dasChoice ${this.state.pfd.codes[key].quad[1] === 1 ? 'bgYellow' : ''}`} onClick={this.updateQuad.bind(this, 1, key, 1)}>BF</div>
                    <div className={`dasChoice ${this.state.pfd.codes[key].quad[2] === 1 ? 'bgYellow' : ''}`} onClick={this.updateQuad.bind(this, 2, key, 1)}>LS</div>
                    <div className={`dasChoice ${this.state.pfd.codes[key].quad[3] === 1 ? 'bgYellow' : ''}`} onClick={this.updateQuad.bind(this, 3, key, 1)}>RS</div>
                    <div className={`dasChoice ${this.state.pfd.codes[key].quad[4] === 1 ? 'bgYellow' : ''}`} onClick={this.updateQuad.bind(this, 4, key, 1)}>TA</div>
                    <div className={`dasChoice ${this.state.pfd.codes[key].quad[5] === 1 ? 'bgYellow' : ''}`} onClick={this.updateQuad.bind(this, 5, key, 1)}>TF</div>
                  </div>
                </div>
              </div>}

            {das && dasD && dasF && <div className="dasContainerBorder"/>}

            {das && dasF && <div>
                <div className="dsTitle"><h5>DAS Camera <span className="bgRed">Fails</span></h5></div>
                <div className="dsInner">
                  <div className="dasChoices">
                    <div className={`dasChoice ${this.state.pfd.codes[key].quad[0] === 2 ? 'bgRed' : ''}`} onClick={this.updateQuad.bind(this, 0, key, 2)}>BA</div>
                    <div className={`dasChoice ${this.state.pfd.codes[key].quad[1] === 2 ? 'bgRed' : ''}`} onClick={this.updateQuad.bind(this, 1, key, 2)}>BF</div>
                    <div className={`dasChoice ${this.state.pfd.codes[key].quad[2] === 2 ? 'bgRed' : ''}`} onClick={this.updateQuad.bind(this, 2, key, 2)}>LS</div>
                    <div className={`dasChoice ${this.state.pfd.codes[key].quad[3] === 2 ? 'bgRed' : ''}`} onClick={this.updateQuad.bind(this, 3, key, 2)}>RS</div>
                    <div className={`dasChoice ${this.state.pfd.codes[key].quad[4] === 2 ? 'bgRed' : ''}`} onClick={this.updateQuad.bind(this, 4, key, 2)}>TA</div>
                    <div className={`dasChoice ${this.state.pfd.codes[key].quad[5] === 2 ? 'bgRed' : ''}`} onClick={this.updateQuad.bind(this, 5, key, 2)}>TF</div>
                  </div>
                </div>
              </div>}
          </div>}
        </div>
      )
    } else {
      return (
        <div className={`optContainer ${key === "other" ? 'other' : ''}`} key={`${code}-row`}>
          <label className="codeItem">{label}</label>
          <input className="codeItem cbItem" onChange={this.updatePFD} type="checkbox" id={`code-${key}-deg`} />
          <input className="codeItem cbItem" checked={this.state.pfd.codes[key].fail} onChange={this.updatePFD} type="checkbox" id={`code-${key}-fail`} />
          <TextField
            id={`code-${key}-desc`}
            className="codeItem"
            value={this.state.pfd.codes[key].desc}
            onChange={this.updatePFD}
            margin="dense"
            variant="outlined"
            multiline
            rowsMax="7"
          />
        </div>
      );
    }
  }

  // initialQuestions example array: [3, false, true, 3, 3]
  async updateStages(key, val) {
    const stage = STAGES.indexOf(key);
    const initialQuestions = JSON.parse(JSON.stringify(this.state.initialQuestions));
    initialQuestions[stage] = val;
    // clear future stages
    const futureStages = 4 - stage;
    for(var i=1; i <= futureStages; i++){
      let s = stage + i;
      if (stage === 0 && val === 1) {
        switch(s) {
          case 1: 
            initialQuestions[s] = false;
            break;
          case 2:
            initialQuestions[s] = null;
            break;
          default:
            initialQuestions[s] = 0;
        }
      } else {
        initialQuestions[s] = null;
      }
    }
    const pfdState = JSON.parse(JSON.stringify(this.state.pfd));
    await this.setState({
      initialQuestions,
      pfd: {
        ...pfdState,
        [key]: val
      }
    })
  }

  handleDiscFormCheckboxChange = (e) => {
    this.setState({ assocICAW: e.target.checked })
  }

  showStage(stage) {
    // stages = [0,1,2,3,4]
    const iq = JSON.parse(JSON.stringify(this.state.initialQuestions));
    switch(stage) {
      case 1:
        return iq[0] > 1
        break;
      case 2:
        return (iq[0] === 1) || iq[1]
        break;
      case 3:
        return iq[1] && iq[2] 
        break;
      case 4:
        return iq[1] && iq[2] && (iq[3] > 0)
        break;
      default:
        return true
    }
  }

  renderBtn(key, label, val) {
    return (
      <div key={`btn-${key}-${label.toLowerCase()}`} className={`sqBtn ${this.state.initialQuestions[STAGES.indexOf(key)] === val ? 'selectedSQ' : ''}`} onClick={this.updateStages.bind(this, key, val)}>{label}</div>
    );
  }

  renderQuestion(q, i) {
    return (
      <div key={`question-${i}`} style={{zIndex:1 + i}} className={`stageWrapper ${this.showStage(i) ? 'relevantStage' : ''}`}>
        <h4>{q.q}</h4>
        <div className="questionContainer">
          {["multi", "bool"].map(type => ((q.t === type) && q.c.map((val, i) => (this.renderBtn(q.k, val, (type === "multi" ? Number(val) : Bool(val)) )))))}
        </div>
      </div>
    )
  }

  async updateReason(i, event) {
    const val = event.target.value;
    const otherQuestions = JSON.parse(JSON.stringify(this.state.otherQuestions));
    otherQuestions[i].reason = val;
    await this.setState({ otherQuestions });
  }

  async handleOQAnswer(i, answer) {
    const otherQuestions = JSON.parse(JSON.stringify(this.state.otherQuestions));
    const bool = (answer === 'Yes');
    otherQuestions[i].selected = bool;
    const event = {target: { value: "" }};
    if (!bool) { await this.updateReason(i, event) }
    switch(i) {
      case 0:
        this.setState({
          otherQuestions,
          showVSInput: bool
        });
        break;
      case 1:
        this.setState({
          otherQuestions,
          showCIInput: bool
        });
        break;
      case 2:
        this.setState({ otherQuestions });
        break;
      case 3:
        this.setState({
          otherQuestions,
          showOccModal: bool
        });
        break;
    }
  }

  closeReasonModal(modalKey) {
    this.setState({ [modalKey]: false })
  }

  renderReasonModal = (i, title, modalKey) => {
    return (
      <Modal
        key={`modal-${i}`}
        style={modalStyles}
        isOpen={this.state[modalKey]}
      >
        <div>
          <div>
            <h4>{title}</h4>
          </div>
          <TextField
            placeholder="reason"
            onChange={this.updateReason.bind(this, i)}
            value={this.state.otherQuestions[i].reason}
            margin="dense"
            variant="outlined"
            multiline
            rowsMax="7"
          />
        </div>
        <div className="modalButtons">
          <Button onClick={this.closeReasonModal.bind(this, modalKey)} size="medium" variant="outlined" color="inherit">Close</Button>
          <Button onClick={this.closeReasonModal.bind(this, modalKey)} size="medium" variant="outlined" color="primary">Done</Button>
        </div>
      </Modal>
    );
  }

  createData(data) {
    return API.post("data", "/data", {
      body: data
    });
  }

  handleSubmit = async () => {
    let data = {...this.state};
    let pfd = data.pfd;
    delete data.pfd;
    delete data.ICAWS;
    delete data.initialQuestions;
    delete data.tot;
    delete data.discIdBeingEdited;
    delete data.submitted;
    delete data.assocICAW;
    delete data.currentDesc;
    delete data.currentICAW;
    delete data.inDiscEditMode;
    delete data.showICAWOptions;
    delete data.showCIInput;
    delete data.showVSInput;
    delete data.showOccModal;
    delete data.showAlert;
    delete data.discrepancies;
    delete data.discFormShown;
    delete data.otherQuestions;
    const otherQuestions = this.state.otherQuestions.filter(o => o.key);
    const otherQs = {};
    for (var i = otherQuestions.length - 1; i >= 0; i--) {
      otherQs[otherQuestions[i].key] = otherQuestions[i].selected;
    }
    let dataCopy = {
      ...data,
      ...pfd,
      ...otherQs
    }
    let content = JSON.stringify(dataCopy);
    try {
      await this.createData({ content, type: "pfd" });
    } catch (e) {
      console.log(`error on save:`, e);
      this.setState({ isLoading: false });
    }
    await this.generateDataCookie();
    const showOccModal = this.state.showOccModal;
    await this.setState({ ...initialState, showOccModal, submitted: true, showAlert: true });
    const node = this.pageRef.current;
    node.querySelector("#takeoffTimeHour").value = "";
    node.querySelector("#takeoffTimeMinute").value = "";
    node.querySelector("#landTimeHour").value = "";
    node.querySelector("#landTimeMinute").value = "";
    window.scrollTo(0, 0);
  }

  handleICAWChange = (val) => {
    const newList = [...INITIAL_ICAWS].filter(icaw => val && val !== '' && icaw.label.toLowerCase().includes(val.toLowerCase()));
    this.setState({ ICAWS: newList, currentICAW: val, showICAWOptions: true })
  }

  handleICAWSelect = (val) => {
    this.setState({ currentICAW: val, showICAWOptions: false })
  }

  updateCurrentDesc = (e) => {
    const desc = e.target.value;
    this.setState({
      currentDesc: desc
    })
  }

  addDisc = () => {
    const assocICAW = this.state.assocICAW;
    const icaw = this.state.currentICAW;
    const desc = this.state.currentDesc;
    const id = Math.floor(100000000 + Math.random() * 900000000);
    const shortDesc = `${desc.substring(0,10)}...`;
    const allDescs = [...this.state.extraDiscrepancies];
    let newDisc = {
      id,
      shortDesc,
      desc: desc
    }
    if (assocICAW) {
      newDisc['icaw'] = icaw;
    }
    allDescs.push(newDisc);
    this.setState({
      extraDiscrepancies: allDescs,
      assocICAW: true,
      currentICAW: "",
      currentDesc: "",
      discIdBeingEdited: null,
      inDiscEditMode: false,
      discFormShown: false
    })
  }

  getDiscIndex = (id) => {
    return Number(this.state.extraDiscrepancies.map((c,i) => id === c.id ? i : null).filter(v => v).join(''));
  }

  editDisc = (id, b, c) => {
    const allDiscs = [...this.state.extraDiscrepancies];
    const indexToEdit = this.getDiscIndex(id);
    const disc = {...allDiscs[indexToEdit]};
    const assocICAW = disc.icaw ? true : false;
    this.setState({
      inDiscEditMode: true,
      discIdBeingEdited: id,
      currentICAW: disc.icaw,
      assocICAW,
      currentDesc: disc.desc,
      discFormShown: true
    })
  }

  updateDisc = () => {
    const assocICAW = this.state.assocICAW;
    const icaw = this.state.currentICAW;
    const desc = this.state.currentDesc;
    const id = this.state.discIdBeingEdited;
    const shortDesc = `${desc.substring(0,10)}...`;
    const allDiscs = [...this.state.extraDiscrepancies];
    let updatedDisc = {
      id,
      shortDesc,
      desc: desc
    }
    const indexToReplace = this.getDiscIndex(id);
    if (assocICAW) {
      updatedDisc['icaw'] = icaw;
    }
    allDiscs.splice(indexToReplace, 1, updatedDisc);
    this.setState({
      extraDiscrepancies: allDiscs,
      assocICAW: true,
      currentICAW: "",
      currentDesc: "",
      discIdBeingEdited: null,
      inDiscEditMode: false,
      discFormShown: false
    })
  }

  removeDisc = (id) => {
    const allDiscs = [...this.state.extraDiscrepancies];
    const indexToRemove = this.getDiscIndex(id);
    allDiscs.splice(indexToRemove,1);
    this.setState({
      extraDiscrepancies: allDiscs
    })
  }

  updateTime = (key, time) => {
    this.setState({ [key]: time });
  }

  setTakeOffTime = (tot) => {
    this.setState({ tot });
  }

  generateDataCookie = () => {
    const tailNumber = this.state.pfd.aircraft;
    const takeOffTime = this.state.tot;
    const discrepancies = this.state.extraDiscrepancies;
    const date = this.state.pfd.date;
    const missionType = this.state.pfd.tacticalMissionType;
    const data = {
      date,
      tailNumber,
      takeOffTime,
      missionType,
      discrepancies
    }
    const dataString = JSON.stringify(data);
    cookies.set('pfd', dataString, { path: '/' });
  }

  render() {
    const flightMinutes = this.state.landTime - this.state.takeOffTime;
    const aircraftFound = ((this.state.pfd || {}).aircraft || "") === "425";
    const keyCodes = Object.keys(this.state.pfd.codes);
    const ed = this.state.extraDiscrepancies;
    const asnOptions = [
      { target: { "value": '410', id: "aircraft" }, value: '410', label: "410" },
      { target: { "value": '411', id: "aircraft" }, value: '411', label: "411" },
      { target: { "value": '412', id: "aircraft" }, value: '412', label: "412" },
      { target: { "value": '413', id: "aircraft" }, value: '413', label: "413" },
      { target: { "value": '414', id: "aircraft" }, value: '414', label: "414" },
      { target: { "value": '415', id: "aircraft" }, value: '415', label: "415" },
      { target: { "value": '416', id: "aircraft" }, value: '416', label: "416" },
      { target: { "value": '417', id: "aircraft" }, value: '417', label: "417" },
      { target: { "value": '418', id: "aircraft" }, value: '418', label: "418" },
      { target: { "value": '419', id: "aircraft" }, value: '419', label: "419" },
      { target: { "value": '420', id: "aircraft" }, value: '420', label: "420" },
      { target: { "value": '421', id: "aircraft" }, value: '421', label: "421" },
      { target: { "value": '422', id: "aircraft" }, value: '422', label: "422" },
      { target: { "value": '423', id: "aircraft" }, value: '423', label: "423" },
      { target: { "value": '424', id: "aircraft" }, value: '424', label: "424" },
      { target: { "value": '425', id: "aircraft" }, value: '425', label: "425" },
      { target: { "value": '426', id: "aircraft" }, value: '426', label: "426" },
      { target: { "value": '427', id: "aircraft" }, value: '427', label: "427" },
      { target: { "value": '428', id: "aircraft" }, value: '428', label: "428" }
    ]

    return (
      <div ref={this.pageRef} className="NewPFD">
        <div className="successAlertContainer">
          {this.state.showAlert && <Alert className="successAlert" onClose={this.hideAlert}>Form successfully submitted.</Alert>}
        </div>
        <div className="pfdTitle">
          <h2>Post Flight Data</h2>
        </div>
        <div className="NewPFDContainer">
          <div className="leftSection">
            {this.renderModal()}
            <div className="flexContainer">
              <div className="flexSubContainer mr-11">
                <div>Squadron</div>
                <input id="squadron" value={this.state.pfd.squadron} onChange={this.updatePFD} type="text"/>
              </div>
              <div className="flexSubContainer mr-11">
                <div>Pilot Name</div>
                <input id="pilot" value={this.state.pfd.pilot || "Alex Daie"} onChange={this.updatePFD} type="text"/>
              </div>
              <div className="flexSubContainer mr-11 asn">
                <div className="asnWrapper">Aircraft Side Number</div>
                <Select id="aircraft" className="aircraftSelect" value={asnOptions.filter(option => option.label === this.state.pfd.aircraft)} classNamePrefix="react-select" onChange={this.updatePFD} options={asnOptions} />
              </div>
            </div>
            <div className="flexContainer mt-sm">
              <div className="flexSubContainer mr-11">
                <div>Event Date</div>
                <input id="date" value={this.state.pfd.date} onChange={this.updatePFD} type="date"/>
              </div>
              <div className="flexSubContainer mr-11">
                <div>Event Number</div>
                <input id="eNum" max="99" value={this.state.pfd.eNum || ''} onChange={this.updatePFD} type="number"/>
              </div>
            </div>
            <div className="flightTime">
              <div className="flexContainer mb-sm mt-sm timeSelectArea">
                <div className="flexSubContainer mr-11">
                  <div>Takeoff Time</div>
                  <TimeSelect customClass="takeoffTime" handler={this.updateTime} totUpdater={this.setTakeOffTime} timeKey="takeOffTime" />
                </div>
                <div className="flexSubContainer mr-11">
                  <div>Land Time</div>
                  <TimeSelect customClass="landTime" handler={this.updateTime} timeKey="landTime" />
                </div>
              </div>
              <div className="flexContainer mt-sm">
                <div className="flexSubContainer mr-11">
                  <div>Total Flight Time</div>
                  <div><b>{(flightMinutes/60).toFixed(1)} hrs</b></div>
                </div>
              </div>
            </div>

            <div className="tmtRadiosContainer">
              <FormLabel className="tmtLegend" component="legend">Mission Type</FormLabel>
              <RadioGroup className="tmtRadios" aria-label="position" id="tacticalMissionType" name="tacticalMissionType" value={this.state.pfd.tacticalMissionType} onChange={this.updatePFD}>
                <FormControlLabel
                  value="A/A"
                  control={<Radio color="primary" />}
                  label="A/A"
                  id="tacticalMissionType"
                  labelPlacement="start"
                />
                <FormControlLabel
                  value="A/S"
                  control={<Radio color="primary" />}
                  label="A/S"
                  id="tacticalMissionType"
                  labelPlacement="start"
                />
                <FormControlLabel
                  value="SEAD"
                  control={<Radio color="primary" />}
                  label="SEAD"
                  id="tacticalMissionType"
                  labelPlacement="start"
                />
                <FormControlLabel
                  value="Other"
                  control={<Radio color="primary" />}
                  label="Other"
                  id="tacticalMissionType"
                  labelPlacement="start"
                />
              </RadioGroup>
            </div>
            <div>
              <h4>Did you have any mission system degrades?</h4>
              <div className="questionContainer">
                <div className={`sqBtn ${this.state.pfd.degrades === true ? 'selectedSQ' : ''}`} onClick={this.updateMSD.bind(this,'YES')}>Yes</div>
                <div className={`sqBtn ${this.state.pfd.degrades === false ? 'selectedSQ' : ''}`} onClick={this.updateMSD.bind(this,'No')}>No</div>
              </div>
            </div>
            {this.state.pfd.degrades && 
              <div>
                <h4>Did these degrades impact mission success?</h4>
                <div className="questionContainer">
                  <div className={`sqBtn ${this.state.pfd.degradesAffectMission === true ? 'selectedSQ' : ''}`} onClick={this.updateDAM.bind(this,'YES')}>Yes</div>
                  <div className={`sqBtn ${this.state.pfd.degradesAffectMission === false ? 'selectedSQ' : ''}`} onClick={this.updateDAM.bind(this,'No')}>No</div>
                </div>
              </div>
            }
            {QUESTIONS.map((q, i) => (this.renderQuestion(q, i)))}
            <div className="generalQuestions">
              <div>
                <h4>Start-up Cold Iron required?</h4>
                <div className="questionContainer">
                  <div className={`sqBtn ${this.state.otherQuestions[0].selected ? 'selectedSQ' : ''}`} onClick={this.handleOQAnswer.bind(this, 0, 'Yes')}>Yes</div>
                  <div className={`sqBtn ${this.state.otherQuestions[0].selected === false ? 'selectedSQ' : ''}`} onClick={this.handleOQAnswer.bind(this, 0, 'No')}>No</div>
                </div>
                {this.state.otherQuestions[0].selected && <div className="reasonInput">
                  <input placeholder="Reason" value={this.state.pfd.startUpColdIronReason} id="startUpColdIronReason" onChange={this.updatePFD} type="text"/>
                </div>}
              </div>
              <div>
                <h4>Post-flight VS-Bit?</h4>
                <div className="questionContainer">
                  <div className={`sqBtn ${this.state.otherQuestions[1].selected ? 'selectedSQ' : ''}`} onClick={this.handleOQAnswer.bind(this, 1, 'Yes')}>Go</div>
                  <div className={`sqBtn ${this.state.otherQuestions[1].selected === false ? 'selectedSQ' : ''}`} onClick={this.handleOQAnswer.bind(this, 1, 'No')}>No Go</div>
                </div>
                {(this.state.otherQuestions[1].selected === false) && <div className="reasonInput">
                  <input className="reasonInput" placeholder="Reason" value={this.state.pfd.postFlightVSBitNoGoReason} id="postFlightVSBitNoGoReason" onChange={this.updatePFD} type="text"/>
                </div>}
              </div>
              <div>
                <h4>Additional Discrepancies</h4>
              </div>
              {ed.length > 0 && ed.map((disc, idx) => (
                <div className="addedDisc" key={`disc-${idx}`}>
                  <div><span style={{ backgroundColor: ICAW_HEX_BG[disc.icaw], color: ICAW_HEX_COLOR[disc.icaw], padding: '3px 5px', border: '2px solid #000'}}>{disc.icaw}</span> - {disc.shortDesc}</div>
                  <div className="addedDiscButtons">
                    <IconButton
                      onClick={this.editDisc.bind(this, disc.id)}
                      aria-label="edit"
                    >
                      <EditIcon className="editIcon" />
                    </IconButton>
                    <IconButton
                      onClick={this.removeDisc.bind(this, disc.id)}
                      aria-label="delete"
                    >
                      <DeleteIcon className="deleteIcon" />
                    </IconButton>
                  </div>
                </div>
              ))}
              {!this.state.discFormShown && 
                <div className="newDiscBtn">
                  <Button onClick={this.showDiscForm.bind(this)} size="medium" variant="outlined" color="inherit">Add Discrepancy</Button>
                </div>
              }
              {this.state.discFormShown && <div className="discForm">
                {this.state.inDiscEditMode ? <div className="discFormTitle">
                  Edit Discrepancy
                </div> : <div className="discFormTitle">
                  New Discrepancy
                </div>}
                <div className="discFormBody">

                  <div className="discFormLeft">

                    {this.state.assocICAW && <div className="icawSelect">
                      <TextField
                        className="icawInput"
                        placeholder="ICAW"
                        value={this.state.currentICAW}
                        onChange={(e) => this.handleICAWChange(e.target.value)}
                        onBlur={this.hideICAWOptions}
                        variant="outlined"
                      />
                      {this.state.showICAWOptions && <div className="icawOptionsList">
                        {this.state.ICAWS.map(icaw => (
                          <div key={icaw.label}
                               onClick={() => this.handleICAWSelect(icaw.label)}
                               className="icawOption"
                               style={{ background: ICAW_HEX_BG[icaw.label], color: ICAW_HEX_COLOR[icaw.label], padding: '3px 5px', border: '2px solid #000', marginTop: '1px' }}>
                            {icaw.label}
                          </div>
                        ))}
                      </div>}
                    </div>}
                    <div className="discCheckbox">
                      <div className="discCheckboxTitle">
                        Associated ICAW ?
                      </div>
                      <Checkbox
                        className="discCheckboxWrapper"
                        checked={this.state.assocICAW}
                        onChange={this.handleDiscFormCheckboxChange}
                        id="discCheckbox"
                        color="primary"
                      />
                    </div>

                  </div>

                  <textarea rows="8" cols="50" placeholder="Discrepancy details" value={this.state.currentDesc} onChange={this.updateCurrentDesc} />
                </div>
                <div className="discFormActions">
                  <div className="cancelBtn"><Button onClick={this.hideDiscForm.bind(this)} size="medium" variant="outlined" color="inherit">Cancel</Button></div>
                  {this.state.inDiscEditMode ? <Button onClick={this.updateDisc.bind(this)} size="medium" variant="outlined" color="inherit">Update</Button> : <Button onClick={this.addDisc.bind(this)} size="medium" variant="outlined" color="inherit">Add</Button>}
                </div>
              </div>}
              <div>
                <h4>Did you have any Aircraft System issues that led to an In-Flight Mission Abort?</h4>
                <div className="questionContainer">
                  <div className={`sqBtn ${this.state.otherQuestions[3].selected ? 'selectedSQ' : ''}`} onClick={this.handleOQAnswer.bind(this, 3, 'Yes')}>Yes</div>
                  <div className={`sqBtn ${this.state.otherQuestions[3].selected === false ? 'selectedSQ' : ''}`} onClick={this.handleOQAnswer.bind(this, 3, 'No')}>No</div>
                </div>
              </div>
            </div>
          </div>

          <div className="rightSection">
            {this.state.pfd.degrades && <div className="codesTable">
              <div className="degdsTitle">
                <h4>Select Mission Degrades</h4>
              </div>
              <div className="degFailContainer dgcTop">
                <label className="dfItem dfItem--dg"></label>
                <label className="dfItem dfItem--fl"></label>
                <label className="dfItem dfItem--ds"><span>Amplifying Info</span></label>
              </div>
              <div className="degFailContainer dgcBottom">
                <label className="dfItem dfItem--dg">Degd</label>
                <label className="dfItem dfItem--fl">Fail</label>
                <label className="dfItem dfItem--ds"><p><i>(if required)</i></p></label>
              </div>
              <div className="codes">
                {keyCodes.map((key, i) => (this.renderCodeRow(key, i)))}
              </div>
            </div>}

          </div>
        </div>
        <div className="bottomSection">
          <Button onClick={this.handleSubmit} size="large" variant="outlined" color="inherit">
            Submit
          </Button>
        </div>
      </div>
    );
  }
}
