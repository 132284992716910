import "./App.css";
import React, { Component, Fragment } from "react";
import { Link, withRouter } from "react-router-dom";
import Chip from '@material-ui/core/Chip';
import { NavItem } from "react-bootstrap";
import Routes from "./Routes";
import { LinkContainer } from "react-router-bootstrap";
// import YatoTold from "./containers/YatoTold";
import { API, Auth, Storage } from "aws-amplify";

class App extends Component {
  constructor(props) {
    super(props);
    let path = this.props.location.pathname;
    // const showAlertState = localStorage.getItem('showNewDocsAlert');
    // if (showAlertState) {
    //   showNewDocsAlert = showAlertState === "false" ? false : showNewDocsAlert;
    // }
    let showNewDocsAlert = path === '/' ? true : false;
    this.state = {
      isAuthenticated: false,
      isAuthenticating: true,
      data: [],
      bgToggled: false,
      currentTabIndex: 0,
      activeSubNav: '',
      showSRSubNav: false,
      showProfileSubNav: false,
      showInfoSubNav: false,
      showPDSubNav: false,
      activeLink: '',
      subLink: '',
      parentNavClicks: 0,
      showNewDocsAlert
    };

    this.toggleBG = this.toggleBG.bind(this)
    this.updateTabIndex = this.updateTabIndex.bind(this)
    this.updateData = this.updateData.bind(this)
  }

  async componentDidMount() {

    try {
      await Auth.currentSession();
      this.userHasAuthenticated(true);
    }
    catch(e) {
      if (e !== 'No current user') {
        alert(e);
      }
    }

    try {
      const allData = await this.data();
      const data = allData.filter(d => (typeof d.content !== 'string'));
      // try {
      //   const dataWithAttachmentURLS = await Promise.all(data.map(item => this.setAttachmentURL(item)));
      //   await this.setState({ data: dataWithAttachmentURLS });
      // } catch(e) {
      //   console.log("error:", e);
      // }
      const pfdData = allData.filter(d => (typeof d.content === 'string'))
                          .map(d => d.content)
                          .map(d => JSON.parse(d));      
      this.setState({ isAuthenticating: false, data, pfdData });
    } catch (e) {
      alert(e);
    }
  }

  handleAlertDelete() {
    localStorage.setItem('showNewDocsAlert', false);
    this.setState({ showNewDocsAlert: false })
  }

  updateData(newRecord) {
    let data = JSON.parse(JSON.stringify(this.state.data));
    this.setState({
      data: [...data, newRecord]
    });
  }

  toggleBG(state = 'open') {
    const bgToggled = state === 'close' ? true : false;
    this.setState({
      bgToggled
    });
  }

  async handleNavClick(activeLink, subLink) {
    await this.setState({
      activeLink,
      subLink,
      parentNavClicks: this.state.parentNavClicks + 1
    })
  }

  openBG() {
    const bgToggled = false;
    this.setState({
      bgToggled,
      currentTabIndex: 0
    });
  }

  updateTabIndex(index = 0) {
    this.setState({
      currentTabIndex: index
    })
  }

  async setAttachmentURL(item) {
    if (item.attachment) {
      const attachmentURL = await Storage.vault.get(item.attachment);
      const dataObj = JSON.parse(JSON.stringify(item));
      const newObj = {
        ...dataObj,
        attachmentURL
      }
      return newObj;
    } else {
      return item;
    }
  }

  async data() {
    if (!this.state.isAuthenticated) {
      return [];
    }
    try {
      let data = await API.get("data", "/data");
      return data;
    } catch(error) {
      console.log(`Error getting all data:`,error);
    }
  }

  userHasAuthenticated = authenticated => {
    this.setState({ isAuthenticated: authenticated });
  }

  handleLogout = async event => {
    await Auth.signOut();

    this.userHasAuthenticated(false);
    this.props.history.push("/login");
  }


  /* Safety Reports Navigation */
  showSRSubNavigation = async () => {
    await this.setState({
      showSRSubNav: true,
      activeSubNav: ''
    })
  }

  showPDSubNavigation = async () => {
    await this.setState({
      showPDSubNav: true,
      activeSubNav: ''
    })
  }

  resetSRSubNav = () => {
    this.setState({
      showSRSubNav: false,
      activeSubNav: ''
    })
  }

  resetPDSubNav = () => {
    this.setState({
      showPDSubNav: false,
      activeSubNav: ''
    })
  }


  /* Profile Navigation */
  showProfileSubNavigation = async () => {
    await this.setState({
      showProfileSubNav: true,
      activeSubNav: ''
    })
  }

  resetProfileSubNav = () => {
    this.setState({
      showProfileSubNav: false,
      activeSubNav: ''
    })
  }



  /* Additional Info Navigation */
  showInfoSubNavigation = async () => {
    await this.setState({
      showInfoSubNav: true,
      activeSubNav: ''
    })
  }

  resetInfoSubNav = () => {
    this.setState({
      showInfoSubNav: false,
      activeSubNav: ''
    })
  }



  setActiveSubNav(activeSubNav) {
    if (this.state.activeSubNav === activeSubNav) { return }
    this.setState({ activeSubNav });
  }

  render() {
    const childProps = {
      isAuthenticated: this.state.isAuthenticated,
      userHasAuthenticated: this.userHasAuthenticated,
      subLink: this.state.subLink,
      data: this.state.data,
      pfdData: this.state.pfdData,
      updateData: this.updateData,
      bgToggled: this.state.bgToggled,
      toggleBG: this.toggleBG,
      updateTabIndex: this.updateTabIndex,
      currentTabIndex: this.state.currentTabIndex,
      parentNavClicks: this.state.parentNavClicks
    };

    if (this.props.location.pathname === '/yatotold') {
      // return (
      //   <YatoTold/>
      // )
      return (<div>Under Development</div>)
    } else {
      return (
          !this.state.isAuthenticating &&
          <div className="App" onMouseDown={this.handleMouseDown}>
            <div className="navbarContainer no-print">
              <div onClick={this.openBG.bind(this)} className="logoContainer no-print">
                <div className="svg logo">
                  <img src="/logo.svg"/>
                </div>
                <div className="title titleB">
                  <Link to="/"
                    onClick={this.handleNavClick.bind(this, 'home', '')}><span className="yato">ARES</span></Link>
                </div>
              </div>
              <div className="navLinks no-print">
              {this.state.isAuthenticated
                ? <Fragment>
                    {/* Safety Reports Navigation */}
                    <div onMouseLeave={this.resetSRSubNav} className="nestedDropDownContainer">
                      <NavItem
                        className={`yatoLink yatoLink-left ${this.state.activeLink === 'reports' ? 'activeLink' : ''}`}
                        onMouseEnter={this.showSRSubNavigation}
                      >SAFETY REPORTS</NavItem>
                      {this.state.showSRSubNav && <div className="dropDownList">
                        <ul>
                          <NavItem className="yatoLink subNav" onMouseEnter={this.setActiveSubNav.bind(this,'or')}>Occurrence Reports</NavItem>
                          <NavItem className="yatoLink subNav" onMouseEnter={this.setActiveSubNav.bind(this,'hr')}>Hazard Reports</NavItem>
                          <NavItem className="yatoLink subNav" onMouseEnter={this.setActiveSubNav.bind(this,'mr')}>Mishap Reports</NavItem>
                          <NavItem className="yatoLink subNav" onMouseEnter={this.setActiveSubNav.bind(this,'ar')}>Action Requests</NavItem>
                          <NavItem className="yatoLink subNav" onMouseEnter={this.setActiveSubNav.bind(this,'all')}>All</NavItem>
                          <LinkContainer to="/data/new">
                            <NavItem className="yatoLink subNav" onMouseEnter={this.setActiveSubNav.bind(this,'nor')}>Submit New Report</NavItem>
                          </LinkContainer>
                        </ul>
                      </div>}

                      {(this.state.showSRSubNav && this.state.activeSubNav === 'or') && <div className="nestedDropDownList srDD">
                        <ul>
                          <LinkContainer to="/reports?type=or&aircraft=f35com&t=a">
                            <NavItem
                              onClick={this.handleNavClick.bind(this, 'reports', 'a')}
                              className="yatoLink subNav">
                                F-35 Common
                            </NavItem>
                          </LinkContainer>
                          <LinkContainer to="/reports?type=or&aircraft=f35a&t=b">
                            <NavItem
                              onClick={this.handleNavClick.bind(this, 'reports', 'b')}
                              className="yatoLink subNav">
                                F-35A
                            </NavItem>
                          </LinkContainer>
                          <LinkContainer to="/reports?type=or&aircraft=f35b&t=c">
                            <NavItem
                              onClick={this.handleNavClick.bind(this, 'reports', 'c')}
                              className="yatoLink subNav">
                                F-35B
                            </NavItem>
                          </LinkContainer>
                          <LinkContainer to="/reports?type=or&aircraft=f35c&t=d">
                            <NavItem
                              onClick={this.handleNavClick.bind(this, 'reports', 'd')}
                              className="yatoLink subNav">
                                F-35C
                            </NavItem>
                          </LinkContainer>
                        </ul>
                      </div>}

                      {(this.state.showSRSubNav && this.state.activeSubNav === 'hr') && <div className="nestedDropDownList srDD srDD--hr">
                        <ul>
                          <LinkContainer to="/reports?type=hr&aircraft=f35com&t=a">
                            <NavItem onClick={this.handleNavClick.bind(this, 'reports', 'a')} className="yatoLink subNav">F-35 Common</NavItem>
                          </LinkContainer>
                          <LinkContainer to="/reports?type=hr&aircraft=f35a&t=b">
                            <NavItem onClick={this.handleNavClick.bind(this, 'reports', 'b')} className="yatoLink subNav">F-35A</NavItem>
                          </LinkContainer>
                          <LinkContainer to="/reports?type=hr&aircraft=f35b&t=c">
                            <NavItem onClick={this.handleNavClick.bind(this, 'reports', 'c')} className="yatoLink subNav">F-35B</NavItem>
                          </LinkContainer>
                          <LinkContainer to="/reports?type=hr&aircraft=f35c&t=d">
                            <NavItem onClick={this.handleNavClick.bind(this, 'reports', 'd')} className="yatoLink subNav">F-35C</NavItem>
                          </LinkContainer>
                        </ul>
                      </div>}

                      {(this.state.showSRSubNav && this.state.activeSubNav === 'mr') && <div className="nestedDropDownList srDD srDD--mr">
                        <ul>
                          <LinkContainer to="/reports?type=mr&aircraft=f35com&t=a">
                            <NavItem onClick={this.handleNavClick.bind(this, 'reports', 'a')} className="yatoLink subNav">F-35 Common</NavItem>
                          </LinkContainer>
                          <LinkContainer to="/reports?type=mr&aircraft=f35a&t=b">
                            <NavItem onClick={this.handleNavClick.bind(this, 'reports', 'b')} className="yatoLink subNav">F-35A</NavItem>
                          </LinkContainer>
                          <LinkContainer to="/reports?type=mr&aircraft=f35b&t=c">
                            <NavItem onClick={this.handleNavClick.bind(this, 'reports', 'c')} className="yatoLink subNav">F-35B</NavItem>
                          </LinkContainer>
                          <LinkContainer to="/reports?type=mr&aircraft=f35c&t=d">
                            <NavItem onClick={this.handleNavClick.bind(this, 'reports', 'd')} className="yatoLink subNav">F-35C</NavItem>
                          </LinkContainer>
                        </ul>
                      </div>}

                      {(this.state.showSRSubNav && this.state.activeSubNav === 'ar') && <div className="nestedDropDownList srDD srDD--ar">
                        <ul>
                          <LinkContainer to="/reports?type=ar&aircraft=f35com&t=a">
                            <NavItem onClick={this.handleNavClick.bind(this, 'reports', 'a')} className="yatoLink subNav">F-35 Common</NavItem>
                          </LinkContainer>
                          <LinkContainer to="/reports?type=ar&aircraft=f35a&t=b">
                            <NavItem onClick={this.handleNavClick.bind(this, 'reports', 'b')} className="yatoLink subNav">F-35A</NavItem>
                          </LinkContainer>
                          <LinkContainer to="/reports?type=ar&aircraft=f35b&t=c">
                            <NavItem onClick={this.handleNavClick.bind(this, 'reports', 'c')} className="yatoLink subNav">F-35B</NavItem>
                          </LinkContainer>
                          <LinkContainer to="/reports?type=ar&aircraft=f35c&t=d">
                            <NavItem onClick={this.handleNavClick.bind(this, 'reports', 'd')} className="yatoLink subNav">F-35C</NavItem>
                          </LinkContainer>
                        </ul>
                      </div>}

                      {(this.state.showSRSubNav && this.state.activeSubNav === 'all') && <div className="nestedDropDownList srDD srDD--all">
                        <ul>
                          <LinkContainer to="/reports?type=all&aircraft=f35com&t=a">
                            <NavItem onClick={this.handleNavClick.bind(this, 'reports', 'a')} className="yatoLink subNav">F-35 Common</NavItem>
                          </LinkContainer>
                          <LinkContainer to="/reports?type=all&aircraft=f35a&t=b">
                            <NavItem onClick={this.handleNavClick.bind(this, 'reports', 'b')} className="yatoLink subNav">F-35A</NavItem>
                          </LinkContainer>
                          <LinkContainer to="/reports?type=all&aircraft=f35b&t=c">
                            <NavItem onClick={this.handleNavClick.bind(this, 'reports', 'c')} className="yatoLink subNav">F-35B</NavItem>
                          </LinkContainer>
                          <LinkContainer to="/reports?type=all&aircraft=f35c&t=d">
                            <NavItem onClick={this.handleNavClick.bind(this, 'reports', 'd')} className="yatoLink subNav">F-35C</NavItem>
                          </LinkContainer>
                        </ul>
                      </div>}

                    </div>
                  {/* Flight Manuals */}
                  <LinkContainer to="/manuals">
                    <NavItem
                      onClick={this.handleNavClick.bind(this, 'fmps')}
                      className={`yatoLink ${this.state.activeLink === 'fmps' ? 'activeLink' : ''}`}>
                        FLIGHT MANUAL PRODUCT SET
                    </NavItem>
                  </LinkContainer>
                  {/* F-35 Performance Data */}
                  <div onMouseLeave={this.resetPDSubNav} className="nestedDropDownContainer">
                    <NavItem
                      onClick={this.handleNavClick.bind(this, 'pd')}
                      className={`yatoLink ${this.state.activeLink === 'pd' ? 'activeLink' : ''}`}
                      onMouseEnter={this.showPDSubNavigation}>F-35 PERFORMANCE DATA</NavItem>
                    {this.state.showPDSubNav && <div className="dropDownList">
                      <ul>
                        <LinkContainer to="/told">
                          <NavItem className="yatoLink subNav" onClick={this.handleNavClick.bind(this, 'told')} onMouseEnter={this.setActiveSubNav.bind(this,'')}>F-35 TOLD</NavItem>
                        </LinkContainer>
                      </ul>  
                    </div>}        
                  </div>
                  {/* Additional Info Navigation */}
                    <div onMouseLeave={this.resetInfoSubNav} className="nestedDropDownContainer">
                      <NavItem
                          className={`yatoLink ${this.state.activeLink === 'info' ? 'activeLink' : ''}`}
                          onMouseEnter={this.showInfoSubNavigation}
                        >ADDITIONAL INFO</NavItem>
                        {this.state.showInfoSubNav && <div className="dropDownList">
                          <ul>
                            <LinkContainer to="/troubleshoot">
                              <NavItem className="yatoLink subNav" onClick={this.handleNavClick.bind(this, 'ppt')} onMouseEnter={this.setActiveSubNav.bind(this,'')}>Software Update Pilot Guides</NavItem>
                            </LinkContainer>
                            <NavItem className="yatoLink subNav" onMouseEnter={this.setActiveSubNav.bind(this,'aar')}>After Action Reports</NavItem>
                            <LinkContainer to="/links">
                              <NavItem className="yatoLink subNav" onClick={this.handleNavClick.bind(this, 'info')} onMouseEnter={this.setActiveSubNav.bind(this,'')}>Useful Links</NavItem>
                            </LinkContainer>
                            <LinkContainer to="/program-info">
                              <NavItem className="yatoLink subNav" onClick={this.handleNavClick.bind(this, 'info')} onMouseEnter={this.setActiveSubNav.bind(this,'')}>F-35 Fast Facts</NavItem>
                            </LinkContainer>
                          </ul>
                        </div>}
                        {(this.state.showInfoSubNav && this.state.activeSubNav === 'aar') && <div className="nestedDropDownList aInfo aar">
                          <ul>
                            <LinkContainer to="/aa-reports?type=orcom">
                              <NavItem onClick={this.handleNavClick.bind(this, 'info', 'a')} className="yatoLink subNav">F-35 Common</NavItem>
                            </LinkContainer>
                            <LinkContainer to="/aa-reports?type=ora">
                              <NavItem onClick={this.handleNavClick.bind(this, 'info', 'b')} className="yatoLink subNav">F-35A</NavItem>
                            </LinkContainer>
                            <LinkContainer to="/aa-reports?type=orb">
                              <NavItem onClick={this.handleNavClick.bind(this, 'info', 'c')} className="yatoLink subNav">F-35B</NavItem>
                            </LinkContainer>
                            <LinkContainer to="/aa-reports?type=orc">
                              <NavItem onClick={this.handleNavClick.bind(this, 'info', 'd')} className="yatoLink subNav">F-35C</NavItem>
                            </LinkContainer>
                          </ul>
                        </div>}
                        {(this.state.showInfoSubNav && this.state.activeSubNav === 'rri') && <div className="nestedDropDownList aInfo rri">
                          <ul>
                            <LinkContainer to="/read-and-initial?type=orcom">
                              <NavItem onClick={this.handleNavClick.bind(this, 'info', 'a')} className="yatoLink subNav">F-35 Common</NavItem>
                            </LinkContainer>
                            <LinkContainer to="/read-and-initial?type=ora">
                              <NavItem onClick={this.handleNavClick.bind(this, 'info', 'b')} className="yatoLink subNav">F-35A</NavItem>
                            </LinkContainer>
                            <LinkContainer to="/read-and-initial?type=orb">
                              <NavItem onClick={this.handleNavClick.bind(this, 'info', 'c')} className="yatoLink subNav">F-35B</NavItem>
                            </LinkContainer>
                            <LinkContainer to="/read-and-initial?type=orc">
                              <NavItem onClick={this.handleNavClick.bind(this, 'info', 'd')} className="yatoLink subNav">F-35C</NavItem>
                            </LinkContainer>
                          </ul>
                        </div>}
                    </div>
                    {/* System Safety Monthly Navigation */}
                    <LinkContainer to="/system-safety">
                      <NavItem
                        onClick={this.handleNavClick.bind(this, 'ssm', 'ee')}
                        className={`yatoLink ${this.state.activeLink === 'ssm' ? 'activeLink' : ''}`}>
                          SYSTEM SAFETY MONTHLY
                      </NavItem>
                    </LinkContainer>
                  {/* Search */}
                    <LinkContainer to="/search">
                      <NavItem
                        onClick={this.handleNavClick.bind(this, 'db', 'ee')}
                        className={`yatoLink ${this.state.activeLink === 'db' ? 'activeLink' : ''}`}>
                          DATABASE
                      </NavItem>
                    </LinkContainer>
                    {/* Profile Navigation */}
                    <div onMouseLeave={this.resetProfileSubNav} className="nestedDropDownContainer">
                      <NavItem
                          className={`yatoLink ${this.state.activeLink === 'account' ? 'activeLink' : ''}`}
                          onMouseEnter={this.showProfileSubNavigation}
                        >PROFILE</NavItem>
                        {this.state.showProfileSubNav && <div className="dropDownList">
                          <ul>
                            <LinkContainer to="/settings">
                              <NavItem onClick={this.handleNavClick.bind(this, 'account', 'ff')} className="yatoLink subNav">Account</NavItem>
                            </LinkContainer>
                            <LinkContainer to="/dashboard">
                              <NavItem onClick={this.handleNavClick.bind(this, 'account', 'dsh')} className="yatoLink subNav">Reporting</NavItem>
                            </LinkContainer>
                            <NavItem className="yatoLink subNav" onClick={this.handleLogout}>Logout</NavItem>
                          </ul>
                        </div>}
                    </div>
                  </Fragment>
                : <Fragment>
                    <LinkContainer to="/login">
                      <NavItem className="yatoLink">Login</NavItem>
                    </LinkContainer>
                  </Fragment>
              }
              </div>
            </div>

            <div className="AppBackground">
                <Routes childProps={childProps} />
            </div>

            <div className="footer no-print">
              <div className="footerItem">Systecon North America, 2021</div>
              <div className="footerItemCenter">Proprietary information and trade secrets. Do not distribute externally.</div>
              <div className="footerItem linkContainer"><a href="/contact">Contact Us</a></div>
            </div>
          </div>
      );
    }

  }

}

export default withRouter(App);

