import React, { Component } from "react";
import "./Contact.css";

export default class Contact extends Component {
  constructor(props) {
    super(props);

    this.state = {};
  }

  render() {
    return (
      <div className="Contact">
        <div className="pageTitle">Contact Us</div>
        <div className="linksContainer">
          <div>Alex Daie</div>
          <div>Alex.Daie@systecon.us</div>
          <div>(480) 297-9548</div>
        </div>
      </div>
    );
  }
}
