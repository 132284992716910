import React, { Component } from "react";
import { FormGroup, FormControl, ControlLabel } from "react-bootstrap";
import LoaderButton from "../components/LoaderButton";
import { Link } from "react-router-dom";
import "./Login.css";
import { Auth } from "aws-amplify";


export default class Login extends Component {
  constructor(props) {
    super(props);

    this.state = {
      isLoading: false,
      email: "",
      password: "",
      newPassword: "",
      confirmNewPassword: "",
      user: null,
      newPasswordRequired: false,
      showConfirmForm: false,
      confirmationCode: ""
    };
  }

  validateForm() {
    return this.state.email.length > 0 && this.state.password.length > 0;
  }

  renderNewPasswordForm() {
    return (
      <form onSubmit={this.handleNewPasswordSubmit}>
        <FormGroup controlId="newPassword" bsSize="large">
          <ControlLabel>Password</ControlLabel>
          <FormControl
            value={this.state.newPassword}
            onChange={this.handleChange}
            type="password"
          />
        </FormGroup>
        <FormGroup controlId="confirmNewPassword" bsSize="large">
          <ControlLabel>Confirm Password</ControlLabel>
          <FormControl
            value={this.state.confirmNewPassword}
            onChange={this.handleChange}
            type="password"
          />
        </FormGroup>
        <LoaderButton
          block
          bsSize="large"
          disabled={!this.validateForm()}
          type="submit"
          isLoading={this.state.isLoading}
          text="Login"
          loadingText="Logging in…"
        />
      </form>
    );
  }

  handleChange = event => {
    this.setState({
      [event.target.id]: event.target.value
    });
  }

  handleNewPasswordSubmit = event => {
    event.preventDefault();
    this.setState({ isLoading: true });
    const user = this.state.user;
    const { requiredAttributes } = user.challengeParam; // the array of required attributes, e.g ['email', 'phone_number']
    
    console.log(`this.state.newPassword ===>>>`,this.state.newPassword);
    Auth.completeNewPassword(
      user,               // the Cognito User Object
      this.state.newPassword     // the new password
    ).then(user => {
      this.setState({
        user,
        isLoading: false
      });
      this.props.userHasAuthenticated(true);
    }).catch(e => {
      console.log(e);
    });
  }

  handleSubmit = event => {
    event.preventDefault();
    this.setState({ isLoading: true });
    Auth.signIn(this.state.email, this.state.password)
      .then(async user => {
          if (user.challengeName === 'NEW_PASSWORD_REQUIRED') {
            await this.setState({
              isLoading: false,
              user,
              newPasswordRequired: true
            });
          } else {
            this.setState({
              user,
              isLoading: false
            });
            this.props.userHasAuthenticated(true);
          }
      }).catch(e => {
        console.log(e.message);
        this.setState({ isLoading: false });
      });
  }

  validateConfirmationForm() {
    return this.state.confirmationCode.length === 6;
  }

  handleConfirmationSubmit = event => {
    event.preventDefault();
    this.setState({ isLoading: true });
    const { user } = this.state;
    if (!user) { return false }
    const code = this.state.confirmationCode;
    const mfaType = user.challengeName;
    Auth.confirmSignIn(user, code, mfaType)
      .then(async loggedUser => {
        this.setState({ isLoading: false });
        this.props.userHasAuthenticated(true);
      }).catch(e => {
        console.log(e.message);
        this.setState({ isLoading: false });
      });
  }

  renderConfirmationForm() {
    return (
      <form onSubmit={this.handleConfirmationSubmit}>
        <FormGroup controlId="confirmationCode" bsSize="large">
          <ControlLabel>Confirmation Code</ControlLabel>
          <FormControl
            autoFocus
            type="tel"
            value={this.state.confirmationCode}
            onChange={this.handleChange}
          />
        </FormGroup>
        <LoaderButton
          block
          bsSize="large"
          disabled={!this.validateConfirmationForm()}
          type="submit"
          isLoading={this.state.isLoading}
          text="Verify"
          loadingText="Verifying…"
        />
      </form>
    );
  }

  render() {
    return (
      <div className="Login">
        {this.state.showConfirmForm ? (this.renderConfirmationForm()) : (<div>
        {this.state.newPasswordRequired ? (this.renderNewPasswordForm()) : (
          <form onSubmit={this.handleSubmit}>
            <FormGroup controlId="email" bsSize="large">
              <ControlLabel>Email</ControlLabel>
              <FormControl
                autoFocus
                type="email"
                value={this.state.email}
                onChange={this.handleChange}
              />
            </FormGroup>
            <FormGroup controlId="password" bsSize="large">
              <ControlLabel>Password</ControlLabel>
              <FormControl
                value={this.state.password}
                onChange={this.handleChange}
                type="password"
              />
            </FormGroup>
            <Link to="/login/reset">Forgot password?</Link>
            <LoaderButton
              block
              bsSize="large"
              disabled={!this.validateForm()}
              type="submit"
              isLoading={this.state.isLoading}
              text="Login"
              loadingText="Logging in…"
            />
          </form>
         )}
         </div>)}
      </div>
    );
  }
}