import React, { Component } from "react";
import "./ReadAndInitial.css";

export default class ReadAndInitial extends Component {
  constructor(props) {
    super(props);

    this.state = {};
  }

  render() {
    return (
      <div className="ReadAndInitial">
        <div className="pageTitle">Recommended Read and Initials</div>
      </div>
    );
  }
}
