import React, { Component } from "react";
import { API, Storage } from "aws-amplify";
import { FormGroup, FormControl, ControlLabel } from "react-bootstrap";
import LoaderButton from "../components/LoaderButton";
import { s3Upload } from "../libs/awsLib";
import config from "../config";
import "./Data.css";


export default class Data extends Component {
  constructor(props) {
    super(props);

    this.file = null;

    this.state = {
      isLoading: null,
      isDeleting: null,
      data: null,
      content: "",
      attachmentURL: null
    };
  }

  async componentDidMount() {
    try {
      let attachmentURL;
      const data = await this.getData();
      const { content, attachment } = data;

      if (attachment) {
        attachmentURL = await Storage.vault.get(attachment);
      }

      this.setState({
        data,
        content,
        attachmentURL
      });
    } catch (e) {
      alert(e);
    }
  }

  getData() {
    return API.get("data", `/data/${this.props.match.params.id}`);
  }

  validateForm() {
    return this.state.content.length > 0;
  }

  formatFilename(str) {
    return str.replace(/^\w+-/, "");
  }

  handleChange = event => {
    this.setState({
      [event.target.id]: event.target.value
    });
  }

  handleFileChange = event => {
    this.file = event.target.files[0];
  }

  saveData(data) {
    return API.put("data", `/data/${this.props.match.params.id}`, {
      body: data
    });
  }

  handleSubmit = async event => {
    let attachment;

    event.preventDefault();

    if (this.file && this.file.size > config.MAX_ATTACHMENT_SIZE) {
      alert(`Please pick a file smaller than ${config.MAX_ATTACHMENT_SIZE/1000000} MB.`);
      return;
    }

    this.setState({ isLoading: true });

    try {
      if (this.file) {
        attachment = await s3Upload(this.file);
      }

      await this.saveData({
        content: this.state.content,
        attachment: attachment || this.state.data.attachment
      });
      this.props.history.push("/");
    } catch (e) {
      alert(e);
      this.setState({ isLoading: false });
    }
  }

  deleteData() {
    return API.del("data", `/data/${this.props.match.params.id}`);
  }

  handleDelete = async event => {
    event.preventDefault();

    const confirmed = window.confirm(
      "Are you sure you want to delete this data?"
    );

    if (!confirmed) {
      return;
    }

    this.setState({ isDeleting: true });

    try {
      await this.deleteData();
      this.props.history.push("/");
    } catch (e) {
      alert(e);
      this.setState({ isDeleting: false });
    }
  }


  render() {
    const content = (this.state.data || {}).content;
    const attachment = (this.state.data || {}).attachment;
    return (
      <div className="Data">
        {content &&
          <div>
            <ul>
              {Object.keys(content).map((itemKey, i) => {
                return (<li key={`item-${i}`}>{content[itemKey]}</li>)
              })}
              <h4>Attached Documents</h4>
              <li key="attachment">
                <a
                  target="_blank"
                  rel="noopener noreferrer"
                  href={this.state.attachmentURL}
                >
                  {this.formatFilename(attachment)}
                </a>
              </li>
            </ul>
          </div>}
      </div>
    );
  }

}



          // <form onSubmit={this.handleSubmit}>
          //   <FormGroup controlId="content">
          //     <FormControl
          //       onChange={this.handleChange}
          //       value={this.state.content}
          //       componentClass="textarea"
          //     />
          //   </FormGroup>
          //   {this.state.data.attachment &&
          //     <FormGroup>
          //       <ControlLabel>Attachment</ControlLabel>
          //       <FormControl.Static>
          //         <a
          //           target="_blank"
          //           rel="noopener noreferrer"
          //           href={this.state.attachmentURL}
          //         >
          //           {this.formatFilename(this.state.data.attachment)}
          //         </a>
          //       </FormControl.Static>
          //     </FormGroup>}
          //   <FormGroup controlId="file">
          //     {!this.state.data.attachment &&
          //       <ControlLabel>Attachment</ControlLabel>}
          //     <FormControl onChange={this.handleFileChange} type="file" />
          //   </FormGroup>
          //   <LoaderButton
          //     block
          //     bsStyle="primary"
          //     bsSize="large"
          //     disabled={!this.validateForm()}
          //     type="submit"
          //     isLoading={this.state.isLoading}
          //     text="Save"
          //     loadingText="Saving…"
          //   />
          //   <LoaderButton
          //     block
          //     bsStyle="danger"
          //     bsSize="large"
          //     isLoading={this.state.isDeleting}
          //     onClick={this.handleDelete}
          //     text="Delete"
          //     loadingText="Deleting…"
          //   />
          // </form>}
