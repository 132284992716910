import React, { Component } from "react";
import "./AAReports.css";

export default class AAReports extends Component {
  constructor(props) {
    super(props);

    this.state = {};
  }

  render() {
    return (
      <div className="AAReports">
        <div className="pageTitle">After Action Requests</div>
      </div>
    );
  }
}
