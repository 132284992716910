import React, { Component } from "react";
import "./SystemSafety.css";

export default class SystemSafety extends Component {
  constructor(props) {
    super(props);

    this.state = {};
  }

  render() {
    return (
      <div className="SystemSafety">
        <div className="pageTitle">System Safety Monthly</div>
        <div className="linksContainer">
          <div className="manualLink">
            <div className="link">
              <a href="#">May 2020</a>
            </div>
          </div>
          <div className="manualLink">
            <div className="link">
              <a href="#">July 2020</a>
            </div>
          </div>
        </div>
      </div>
    );
  }
}