import React, { Component } from "react";
import ReactDOM from 'react-dom';
import "./TimeSelect.css";

export default class TimeSelect extends Component {
  constructor(props) {
    super(props);

    this.state = {
    	hour: '00',
    	minute: '00',
      hourVal: null,
      minuteVal: null,
    	showChoices: false
    };
  }

  getTime = (h, m) => {
    return {
      hour: Number(h),
      minute: Number(m)
    };
  }

  updateHour = (h) => {
    let val = (h.target || {}).value || 0;
    let strVal = val.toString();
  	this.setState({ hour: strVal })
  	const m = this.state.minute;
  	const minutes = this.getMinuteValue(strVal, m);
    if (this.props.timeKey === 'takeOffTime') {
      const time = this.getTime(strVal, m);
      this.props.totUpdater(time);
    }
  	this.props.handler(this.props.timeKey, minutes);
    this.setState({ hourVal: val });
  }

  updateMinute = (m) => {
    let val = (m.target || {}).value || 0;
    let strVal = val.toString();
  	this.setState({ minute: strVal })
  	const h = this.state.hour;
  	const minutes = this.getMinuteValue(h, strVal);
    if (this.props.timeKey === 'takeOffTime') {
      const time = this.getTime(h, strVal);
      this.props.totUpdater(time);
    }
  	this.props.handler(this.props.timeKey, minutes);
    this.setState({ minuteVal: val });
  }

  getMinuteValue = (h, m) => {
  	const hr = Number(h);
  	const min = Number(m);
  	return hr * 60 + min;
  }

  toggleChoices = () => {
  	const choicesState = this.state.showChoices;
  	this.setState({ showChoices: !choicesState });
  }

  componentDidMount() {
    document.addEventListener('click', this.handleClickOutside, true);
    this.updateHour({value: '01'});
    this.updateMinute({value: '01'});
  }

  componentWillUnmount() {
	  document.removeEventListener('click', this.handleClickOutside, true);
  }

  handleClickOutside = event => {
  	const domNode = ReactDOM.findDOMNode(this);

  	if (!domNode || !domNode.contains(event.target)) {
      	this.setState({ showChoices: false });
  	}
  }

  render() {
    return (
      <div className="TimeSelect">
        <input id={`${this.props.customClass}Hour`} placeholder="hr" className="TimeSelectBox TimeSelectHour" onChange={this.updateHour.bind(this)} type="number" />
	    	<div className="TimeSelectDivider">:</div>
        <input id={`${this.props.customClass}Minute`} placeholder="min" className="TimeSelectBox TimeSelectMinute" onChange={this.updateMinute.bind(this)} type="number" />
	    </div>
    );
  }
}
