import React, { Component } from "react";
import Button from '@material-ui/core/Button';
import TextField from '@material-ui/core/TextField';
import "./Links.css";

export default class Links extends Component {
  constructor(props) {
    super(props);

    this.state = {
      metarQuery: "",
      metarURL: "#",
      notamQuery: "",
      notamURL: "#"
    };
  }

  handleMetarChange = (e) => {
    let metarQuery = ((e || {}).target || {}).value || "";
    let metarURL = "#";
    if (metarQuery.length > 0) {
      metarURL = `https://www.aviationweather.gov/metar/data?ids=${metarQuery}&format=raw&taf=on`;
    }
    this.setState({
      metarQuery,
      metarURL
    })
  }

  handleNotamChange = (e) => {
    let notamQuery = ((e || {}).target || {}).value || "";
    let notamURL = "#";
    if (notamQuery.length > 0) {
      notamURL = `https://www.notams.faa.gov/dinsQueryWeb/queryRetrievalMapAction.do?retrieveLocId=${notamQuery}&format=raw&taf=on&actionType=notamRetrievalByICAOs`;
    }
    this.setState({
      notamQuery,
      notamURL
    })
  }

  render() {
    const { metarURL, notamURL } = this.state;

    return (
      <div className="Links">
        <div className="pageTitle">Useful Links</div>

        <div className="linksContainer">
          <a className="link" target="_blank" rel="noopener noreferrer" href="https://asap-navy.com/navy">Navy ASAP Login Page</a>
          <a className="link" target="_blank" rel="noopener noreferrer" href="https://www.Aviationweather.gov">Aviation Weather Center</a>
          <div className="metarsContainer">
            <TextField onChange={this.handleMetarChange} placeholder="KNLC" />
            <Button target={metarURL !== '#' ? '_blank' : ''} variant="outlined" href={metarURL}>View TAFs / METARs</Button>
          </div>
          <a className="link" target="_blank" rel="noopener noreferrer" href="https://www.notams.faa.gov/dinsQueryWeb">Defense Internet NOTAM Service</a>
          <div className="notamsContainer">
            <TextField onChange={this.handleNotamChange} placeholder="KNLC" />
            <Button target={notamURL !== '#' ? '_blank' : ''} variant="outlined" href={notamURL}>View NOTAMs</Button>
          </div>
          <a className="link" target="_blank" rel="noopener noreferrer" href="https://tfr.faa.gov/tfr_map_ims/html">FAA TFR Map</a>
        </div>
      </div>
    );
  }
}
