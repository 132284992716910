import React, { Component } from "react";
import "./Troubleshoot.css";

export default class Troubleshoot extends Component {
  constructor(props) {
    super(props);

    this.state = {};
  }

  render() {
    return (
      <div className="Troubleshoot">
        <div className="pageTitle">Software Differences Pilot Guides</div>
          <div className="linksContainer">
            <div className="manualLink">
              <div className="link">
                <a href="#">30P03</a>
              </div>
            </div>
            <div className="manualLink">
              <div className="link">
                <a href="#">30P04</a>
              </div>
            </div>
            <div className="manualLink">
              <div className="link">
                <a href="#">30P04.5</a>
              </div>
            </div>
          </div>
      </div>
    );
  }
}
