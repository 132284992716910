import React from "react";
import { Route, Switch } from "react-router-dom";
import Home from "./containers/Home";
import NotFound from "./containers/NotFound";
import Login from "./containers/Login";
import AppliedRoute from "./components/AppliedRoute";
import ResetPassword from "./containers/ResetPassword";
import NewData from "./containers/NewData";
import NewPFD from "./containers/NewPFD";

import Reports from "./containers/Reports";
import Manuals from "./containers/Manuals";
import Told from "./containers/Told";
import AAReports from "./containers/AAReports";
import Links from "./containers/Links";
import ReadAndInitial from "./containers/ReadAndInitial";
import Search from "./containers/Search";
import Troubleshoot from "./containers/Troubleshoot";
import ProgramInfo from "./containers/ProgramInfo";
import SystemSafety from "./containers/SystemSafety";
import Dashboard from "./containers/Dashboard";

import Data from "./containers/Data";
import AuthenticatedRoute from "./components/AuthenticatedRoute";
import UnauthenticatedRoute from "./components/UnauthenticatedRoute";
import ChangePassword from "./containers/ChangePassword";
import Settings from "./containers/Settings";
import Contact from "./containers/Contact";


export default ({ childProps }) => {
  return (
    <Switch>
        <UnauthenticatedRoute path="/login" exact component={Login} props={childProps} />
        <UnauthenticatedRoute path="/login/reset" exact component={ResetPassword} props={childProps} />
        <AuthenticatedRoute path="/" exact component={Home} props={childProps} />
        <AuthenticatedRoute path="/data/new" exact component={NewData} props={childProps} />
        <AuthenticatedRoute path="/post-flight/new" exact component={NewPFD} props={childProps} />
        <AuthenticatedRoute path="/data/:id" exact component={Data} props={childProps} />
        <AuthenticatedRoute path="/settings" exact component={Settings} props={childProps} />
        <AuthenticatedRoute path="/dashboard" exact component={Dashboard} props={childProps} />
        <AuthenticatedRoute path="/reports" exact component={Reports} props={childProps} />
        <AuthenticatedRoute path="/manuals" exact component={Manuals} props={childProps} />
        <AuthenticatedRoute path="/told" exact component={Told} props={childProps} />
        <AuthenticatedRoute path="/aa-reports" exact component={AAReports} props={childProps} />
        <AuthenticatedRoute path="/links" exact component={Links} props={childProps} />
        <AuthenticatedRoute path="/read-and-initial" exact component={ReadAndInitial} props={childProps} />
        <AuthenticatedRoute path="/search" exact component={Search} props={childProps} />
        <AuthenticatedRoute path="/troubleshoot" exact component={Troubleshoot} props={childProps} />
        <AuthenticatedRoute path="/program-info" exact component={ProgramInfo} props={childProps} />
        <AuthenticatedRoute path="/contact" exact component={Contact} props={childProps} />
        <AuthenticatedRoute path="/system-safety" exact component={SystemSafety} props={childProps} />

        <AuthenticatedRoute path="/settings/password" exact component={ChangePassword} props={childProps} />
        <Route component={NotFound} />
      </Switch>
)};