import React, { Component } from "react";
import "./Manuals.css";

export default class Manuals extends Component {
  constructor(props) {
    super(props);

    this.state = {};
  }

  render() {
    return (
      <div className="Manuals">
        <div className="pageTitle">Flight Manual Product Sets</div>
        <div className="linksContainer">
          <div className="manualLink">
            <div className="link">
              <a href="#">Flight Manual (F35C-FM-001)</a>
            </div>
          </div>
          <div className="manualLink">
            <div className="link">
              <a href="#">Pilots Checklist (F35C-FCL-001)</a>
            </div>
          </div>
          <div className="manualLink">
            <div className="link">
              <a href="#">Pilots Checklist Change 2 (“PCL” F35C-FCL-001 Change 2)</a>
            </div>
          </div>
          <div className="manualLink">
            <div className="link">
              <a href="#">Weapons Preflight Inspection (“Tac PCL” F35C-WPN-CL-001)</a>
            </div>
          </div>
          <div className="manualLink">
            <div className="link">
              <a href="#">Flight Manual Annex (F35C-FM-ANNEX-001)</a>
            </div>
          </div>
          <div className="manualLink">
            <div className="link">
              <a href="#">Pilots FCF Checklist (F35C-FCF-CL-001)</a>
            </div>
          </div>
          <div className="manualLink">
            <div className="link">
              <a href="#">Currency Notice and Status (F35C-FM-STATUS-001)</a>
            </div>
          </div>
        </div>
      </div>
    );
  }
}
