import React, { Component } from "react";
import FormControl from '@material-ui/core/FormControl';
import Grid from '@material-ui/core/Grid';
import Radio from '@material-ui/core/Radio';
import RadioGroup from '@material-ui/core/RadioGroup';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import LoaderButton from "../components/LoaderButton";
import ReactTooltip from 'react-tooltip';
import "./Told.css";

export default class Told extends Component {
  constructor(props) {
    super(props);

    const toldData = {
      runway: '6000',
      throttle: 'mil',
      cg: '27%',
      pa: '2000',
      rcr: '12',
      temp: '86',
      gw: '52',
      currentNote: ''
    };

    const vvvNotes = [
      "For temperatures less than 0’C (32’F), use Rotation (VROT) and Liftoff (VLO) speeds at 0’C (32’F).",
      "Check Speed (VCHK) is at 1,000 FT Ground Roll.",
      "For Temperatures less than 0’C (32’F), use Check Speed at 0’C (32’F) and add 2% every 10’C (18’F).",
      "Add 1 KT to Check Speed for each 1% of negative runway slope, subtract 1 KT for each 1% of positive slope.",
      "Subtract 1 KT to Rotation Speed for each 1% of negative runway slope, add 1 KT for each 1% of positive slope.",
      "Add headwind to VCHK, subtract tailwind from VCHK.",
      "Red Zones indicate accel during climb out is less than zero."
    ];

    this.notes = {
      grd: [
        "For temperatures less than 0’C (32’F), use Ground Roll Distance at 0’C (32’F) and subtract 3.5% every 10’C (18’F).",
        "Subtract 1% from Ground Roll for each 1 knot of headwind, add 1.3% for each 1 knot of tailwind.",
        "Subtract 3% from Ground Roll for each 1% of negative runway slope, add 5% for each 1% of positive slope. ",
        "Red Zones indicate accel during climb out is less than zero."
      ],
      vchk: vvvNotes,
      vrot: vvvNotes,
      vlo: vvvNotes,
      mas: [
        "For temperatures less than 0’C (32’F), use Maximum Abort Speed at 0’C (32’F) and add 1.5% every 10’C (18’F).",
        "Add headwind or subtract twice tailwind from abort speeds.",
        "Add 1% to Max Abort Speed for each 1% of negative runway slope, subtract 1% for each 1% of positive slope.",
        "Three-point attitude maintained",
        "Throttle chopped 1 sec after abort initiated",
        "“C” indicates coast prior to brake application, brakes applied at Max Brake Application Speed; P-40.",
        "No “C” indicates brakes applied 1 sec after throttle chopped",
        "Aft stick input 1 sec after brakes applied",
        "Nose tire limit speed is 208 KTGS",
        "Red Zones indicate brake energy exceeds fuse plug threshold"
      ]
    }
    this.state = {
      isLoading: false,
      toldData,
      toldDataResult: '',
      showToldDataResult: false,
    };
    this.handleToldDataChange = this.handleToldDataChange.bind(this);
  }

  componentDidMount() {
    if (!this.props.isAuthenticated) {
      return;
    }

    this.setState({ isLoading: false });
  }

  handleToldDataChange(event) {
    if (!this.state.toldData) {
      return false;
    }
    const key = event.target.name;
    const toldDataCopy = JSON.parse(JSON.stringify(this.state.toldData));
    const toldData = {
      ...toldDataCopy,
      [key]: event.target.value
    }
    this.setState({ toldData });
  }

  tempCheckOtherToldData(data) {
    return data.throttle === 'max' &&
           data.runway === '12000' &&
           data.cg === '27' &&
           data.rcr === '23' &&
           data.temp === '86' &&
           data.gw === '56';
  }

  handleNotesLinkClick(currentNote) {
    this.setState({ currentNote })
  }

  calculateToldData(event) {
    event.preventDefault();
    this.setState({ isLoading: true });
    const data = JSON.parse(JSON.stringify(this.state.toldData));
    if (!this.tempCheckOtherToldData(data)) {
      this.setState({
        toldDataResult: 'Unable to calculate.',
        showToldDataResult: true
      });
    } else {
      if (data.pa === '0') {
        this.setState({
          toldDataResult: {
            grd: "2940",
            vchk: "109",
            vrot: "161",
            vlo: "182",
            mas: "215c"
          },
          showToldDataResult: true
        });
      } else if (data.pa === '4000') {
        this.setState({
          toldDataResult: {
            grd: "3900",
            vchk: "92",
            vrot: "161",
            vlo: "178",
            mas: "193c"
          },
          showToldDataResult: true
        });
      }
    }
    this.setState({ isLoading: false });
  }

  renderTOLDForm() {
    const tdata = this.state.toldDataResult;

    return (
      <form onSubmit={this.calculateToldData.bind(this)}>
        <Grid container spacing={3}>
          <Grid item xs={12} md={9}>

            <Grid className="gridItemBorderFirst" item xs={12}>
              <div className="radioGroupContainer">
                <h5 className="radioGroupTitle">Gross Weight (1000's of LBs)</h5>
                <FormControl component="fieldset">
                  <RadioGroup
                    className="dataTypeRadioGroup"
                    aria-label="gw"
                    name="gw"
                    value={this.state.toldData.gw}
                    onChange={this.handleToldDataChange}
                    row
                  >
                    <FormControlLabel labelPlacement="bottom" value="40" control={<Radio color="primary" />} label="40" />
                    <FormControlLabel labelPlacement="bottom" value="44" control={<Radio color="primary" />} label="44" />
                    <FormControlLabel labelPlacement="bottom" value="48" control={<Radio color="primary" />} label="48" />
                    <FormControlLabel labelPlacement="bottom" value="52" control={<Radio color="primary" />} label="52" />
                    <FormControlLabel labelPlacement="bottom" value="56" control={<Radio color="primary" />} label="56" />
                    <FormControlLabel labelPlacement="bottom" value="60" control={<Radio color="primary" />} label="60" />
                    <FormControlLabel labelPlacement="bottom" value="64" control={<Radio color="primary" />} label="64" />
                  </RadioGroup>
                </FormControl>
              </div>
            </Grid>
            <Grid className="gridItemBorder" item xs={12}>
              <div className="radioGroupContainer">
                <h5 className="radioGroupTitle">Runway</h5>
                <FormControl component="fieldset">
                  <RadioGroup
                    className="dataTypeRadioGroup"
                    aria-label="runway"
                    name="runway"
                    value={this.state.toldData.runway}
                    onChange={this.handleToldDataChange}
                    row
                  >
                    <FormControlLabel labelPlacement="bottom" value="6000" control={<Radio color="secondary" />} label="6,000 Feet" />
                    <FormControlLabel labelPlacement="bottom" value="8000" control={<Radio color="secondary" />} label="8,000 Feet" />
                    <FormControlLabel labelPlacement="bottom" value="10000" control={<Radio color="secondary" />} label="10,000 Feet" />
                    <FormControlLabel labelPlacement="bottom" value="12000" control={<Radio color="secondary" />} label="12,000 Feet" />
                    <FormControlLabel labelPlacement="bottom" value="14000" control={<Radio color="secondary" />} label="14,000 Feet" />
                  </RadioGroup>
                </FormControl>
              </div>
            </Grid>
            <Grid className="gridItemBorder" item xs={12}>
              <div className="radioGroupContainer">
                <h5 className="radioGroupTitle">Temperature (C˚/(F˚))</h5>
                <FormControl component="fieldset">
                  <RadioGroup
                    className="dataTypeRadioGroup"
                    aria-label="temp"
                    name="temp"
                    value={this.state.toldData.temp}
                    onChange={this.handleToldDataChange}
                    row
                  >
                    <FormControlLabel labelPlacement="bottom" value="32" control={<Radio color="primary" />} label="0˚(32˚)" />
                    <FormControlLabel labelPlacement="bottom" value="50" control={<Radio color="primary" />} label="10˚(50˚)" />
                    <FormControlLabel labelPlacement="bottom" value="68" control={<Radio color="primary" />} label="20˚(68˚)" />
                    <FormControlLabel labelPlacement="bottom" value="86" control={<Radio color="primary" />} label="30˚(86˚)" />
                    <FormControlLabel labelPlacement="bottom" value="104" control={<Radio color="primary" />} label="40˚(104˚)" />
                    <FormControlLabel labelPlacement="bottom" value="122" control={<Radio color="primary" />} label="50˚(122˚)" />
                  </RadioGroup>
                </FormControl>
              </div>
            </Grid>
            <Grid className="gridItemBorder" item xs={12}>
              <div className="radioGroupContainer">
                <h5 className="radioGroupTitle">Throttle</h5>
                <FormControl component="fieldset">
                  <RadioGroup
                    className="dataTypeRadioGroup"
                    aria-label="throttle"
                    name="throttle"
                    value={this.state.toldData.throttle}
                    onChange={this.handleToldDataChange}
                    row
                  >
                    <FormControlLabel labelPlacement="bottom" value="mil" control={<Radio color="secondary" />} label="Mil" />
                    <FormControlLabel labelPlacement="bottom" value="max" control={<Radio color="secondary" />} label="Max" />
                  </RadioGroup>
                </FormControl>
              </div>
            </Grid>
            <Grid className="gridItemBorder" item xs={12}>
              <div className="radioGroupContainer">
                <h5 className="radioGroupTitle">CG</h5>
                <FormControl component="fieldset">
                  <RadioGroup
                    className="dataTypeRadioGroup"
                    aria-label="cg"
                    name="cg"
                    value={this.state.toldData.cg}
                    onChange={this.handleToldDataChange}
                    row
                  >
                    <FormControlLabel labelPlacement="bottom" value="24" control={<Radio color="primary" />} label="24%" />
                    <FormControlLabel labelPlacement="bottom" value="27" control={<Radio color="primary" />} label="27%" />
                    <FormControlLabel labelPlacement="bottom" value="30" control={<Radio color="primary" />} label="30%" />
                  </RadioGroup>
                </FormControl>
              </div>
            </Grid>
            <Grid className="gridItemBorder" item xs={12}>
              <div className="radioGroupContainer">
                <h5 className="radioGroupTitle">Pressure Altitude (feet)</h5>
                <FormControl component="fieldset">
                  <RadioGroup
                    className="dataTypeRadioGroup"
                    aria-label="pa"
                    name="pa"
                    value={this.state.toldData.pa}
                    onChange={this.handleToldDataChange}
                    row
                  >
                    <FormControlLabel labelPlacement="bottom" value="0" control={<Radio color="secondary" />} label="0" />
                    <FormControlLabel labelPlacement="bottom" value="2000" control={<Radio color="secondary" />} label="2,000" />
                    <FormControlLabel labelPlacement="bottom" value="4000" control={<Radio color="secondary" />} label="4,000" />
                    <FormControlLabel labelPlacement="bottom" value="6000" control={<Radio color="secondary" />} label="6,000" />
                  </RadioGroup>
                </FormControl>
              </div>
            </Grid>
            <Grid className="gridItemBorder" item xs={12}>
              <div className="radioGroupContainer">
                <h5 className="radioGroupTitle">RCR</h5>
                <FormControl component="fieldset">
                  <RadioGroup
                    className="dataTypeRadioGroup"
                    aria-label="rcr"
                    name="rcr"
                    value={this.state.toldData.rcr}
                    onChange={this.handleToldDataChange}
                    row
                  >
                    <FormControlLabel labelPlacement="bottom" value="12" control={<Radio color="primary" />} label="12" />
                    <FormControlLabel labelPlacement="bottom" value="23" control={<Radio color="primary" />} label="23" />
                  </RadioGroup>
                </FormControl>
              </div>
            </Grid>
          </Grid>
          <Grid item xs={12} md={3}>
            <div className="toldControls">
              <div className="toldCalculateButtonContainer">
                <LoaderButton
                  block
                  bsStyle="primary"
                  bsSize="large"
                  type="submit"
                  isLoading={this.state.isLoading}
                  text="Calculate Results"
                  loadingText="Calculating…"
                />
              </div>
              <div className="toldCalculateResults">
                {this.state.showToldDataResult && <div>
                  <div className="resultItem">Ground Roll Distance (Feet): {tdata.grd}</div>
                  <div>V<span className='smallText'>CHK</span> (KCAS): {tdata.vchk}</div>
                  <div>V<span className='smallText'>ROT</span> (KCAS): {tdata.vrot}</div>
                  <div>V<span className='smallText'>LO</span> (KCAS): {tdata.vlo}</div>
                  <div>Max Abort Speed (KCAS): {tdata.mas}</div>
                </div>}
              </div>
              <div className="toldNotes toldCalculateResults">
                <h4 className="notesTitle">Notes</h4>
                <a href="#" className="noteLink" data-tip data-for="note-grd" onClick={this.handleNotesLinkClick.bind(this, 'grd')} >Ground Roll Distance</a>
                <a href="#" className="noteLink" data-tip data-for="note-vchk" onClick={this.handleNotesLinkClick.bind(this, 'vchk')} >V<span className='smallText'>CHK</span></a>
                <a href="#" className="noteLink" data-tip data-for="note-vrot" onClick={this.handleNotesLinkClick.bind(this, 'vrot')} >V<span className='smallText'>ROT</span></a>
                <a href="#" className="noteLink" data-tip data-for="note-vlo" onClick={this.handleNotesLinkClick.bind(this, 'vlo')} >V<span className='smallText'>LO</span></a>
                <a href="#" className="noteLink" data-tip data-for="note-mas" onClick={this.handleNotesLinkClick.bind(this, 'mas')} >Max Abort Speed</a>
              </div>

              {Object.keys(this.notes).map(key => (<ReactTooltip
                className="toldTooltip"
                place="left"
                type="info"
                key={`noteKey-${key}`}
                id={`note-${key}`}
                effect="solid"
              >
                <ul>
                  <span>{this.notes[key].map((note, i)=> (
                    <li key={`note-${i}`}>{note}</li>
                  ))}</span>
                </ul>
              </ReactTooltip>))}

            </div>
          </Grid>
        </Grid>
      </form>
    );
  }

  render() {
    return (
      <div className="Told">
        <div className="toldTitle">F-35 TOLD</div>
        <div className="toldForm">
          {this.renderTOLDForm()}
        </div>
      </div>
    );
  }
}
