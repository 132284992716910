export default {
  MAX_ATTACHMENT_SIZE: 5000000,
  s3: {
    REGION: "us-west-2",
    BUCKET: "yatolink-docs"
  },
  apiGateway: {
    REGION: "us-west-2",
    URL: "https://79lipj4ke0.execute-api.us-west-2.amazonaws.com/prod"
  },
  cognito: {
    REGION: "us-west-2",
    USER_POOL_ID: "us-west-2_zyWK0BzH0",
    APP_CLIENT_ID: "4oj1bl3u411luil1vrfj0gets2",
    IDENTITY_POOL_ID: "us-west-2:8708eb27-6286-4176-9f8e-ff3d9bfa8029"
  }
};