import React, { Component } from "react";
import "./ProgramInfo.css";

export default class ProgramInfo extends Component {
  constructor(props) {
    super(props);

    this.state = {};
  }

  render() {
    return (
      <div className="ProgramInfo">
        <div className="pageTitle">F-35 Fast Facts</div>
        <div className="linksContainer">
          <div className="manualLink">
            <div className="link">
              <a href="#">August 2020</a>
            </div>
          </div>
          <div className="manualLink">
            <div className="link">
              <a target="_blank" href="https://www.f35.com/assets/uploads/documents/F-35_Lightning_II_-_Program_Fast_Facts_-_September_2020.pdf">September 2020</a>
            </div>
          </div>
        </div>
      </div>
    );
  }
}
