import React, { Component } from "react";
import ReactDOM from 'react-dom';
import { PageHeader, ListGroup, ListGroupItem } from "react-bootstrap";
import { LinkContainer } from "react-router-bootstrap";
import IconButton from '@material-ui/core/IconButton';
import SearchIcon from '@material-ui/icons/Search';
import Paper from '@material-ui/core/Paper';
import InputBase from '@material-ui/core/InputBase';
import Button from '@material-ui/core/Button';
import AppBar from '@material-ui/core/AppBar';
import Tabs from '@material-ui/core/Tabs';
import FormControl from '@material-ui/core/FormControl';
import FormLabel from '@material-ui/core/FormLabel';
import LoaderButton from "../components/LoaderButton";
import Tab from '@material-ui/core/Tab';
import Typography from '@material-ui/core/Typography';
import Grid from '@material-ui/core/Grid';
import Radio from '@material-ui/core/Radio';
import RadioGroup from '@material-ui/core/RadioGroup';
import Breadcrumbs from '@material-ui/core/Breadcrumbs';
import ReactTooltip from 'react-tooltip'
import Link from '@material-ui/core/Link';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import Box from '@material-ui/core/Box';
import "react-responsive-carousel/lib/styles/carousel.min.css";
import { Carousel } from 'react-responsive-carousel';

import "./Home.css";

function TabPanel(props) {
  const { children, value, index, ...other } = props;

  return (
    <Typography
      component="div"
      role="tabpanel"
      hidden={value !== index}
      id={`simple-tabpanel-${index}`}
      aria-labelledby={`simple-tab-${index}`}
      {...other}
    >
      <Box p={3}>{children}</Box>
    </Typography>
  );
}

export default class Home extends Component {
  constructor(props) {
    super(props);

    this.searchQueryInput = React.createRef();
    const fmpsData = this.props.data.filter(d=>{return (d.content.dataType === "fmps" && d.attachmentURL) });
    const fmpsA = fmpsData.filter(d=> d.content.aircraft === "F35-A");
    const fmpsB = fmpsData.filter(d=> d.content.aircraft === "F35-B");
    const fmpsC = fmpsData.filter(d=> d.content.aircraft === "F35-C");

    const newsletters = this.props.data.filter(d=>{return (d.content.dataType === "newsletter" && d.attachmentURL) });

    this.state = {
      isLoading: true,
      fmpsA,
      fmpsB,
      fmpsC,
      newsletters,
      hq: "",
      activeMiscLinkIndex: '0'
    };

    this.toggleBG = this.toggleBG.bind(this);
  }

  componentDidMount() {
  	if (!this.props.isAuthenticated) {
  		return;
  	}

  	this.setState({ isLoading: false });
  }

  handleTabChange(event, currentTabIndex) {
    this.props.updateTabIndex(currentTabIndex);
  }

  updateActiveMiscLink(event) {
    const id = event.target.id;
    this.setState({
      activeMiscLinkIndex: id
    });
  }

  renderLander() {
    return (
      <div className="lander">
        <h1>YatoLink</h1>
      </div>
    );
  }

  toggleBG() {
    this.props.toggleBG('close');
  }

  _buildLinkHref = () => {
    if (this.state.hq.length > 0) {
      return '/reports?hq='+this.state.hq;
    } else {
      return ''
    }
  }

  updateHQ = (e) => {
    let value = e.target.value;
    this.setState({
      hq: value
    })
  }

  submitSearch = () => {
    if (!this.state.hq.length > 0) {
      return false
    } else {
      window.location = `/reports?hq=${this.state.hq}`;
    }
  }

  handleSearchKeyDown = (e) => {
    if(e.keyCode == 13 && this.state.hq.length > 0){
      this.submitSearch();
    }
  }

  handleItemClick(val, e) {
    const id = val.split('-');
    const page = id[0];
    const index = id[1];
    let selections = this.state[page].selections;
    selections.push(index);

    this.setState({
      [page]: {
        ...this.state[page],
        selections
      }
    });

    if (this.props.toggleBG) {
      this.props.toggleBG('close');
    }
  }

  clearSelections(page) {
    this.setState({
      [page]: {
        ...this.state[page],
        selections: ["0"]
      }
    });
  }

  handleSelection(page, i) {
    const selections = this.state[page].selections.slice(0,i+1);

    this.setState({
      [page]: {
        ...this.state[page],
        selections
      }
    });
  }

  renderBreadCrumbs(page) {
    const pageData = this.state[page];
    const sLen = pageData.selections.length.toString();

    return (
      <Breadcrumbs aria-label="breadcrumb">
        {pageData.selections.map((selection, i) => {
          if (i === (sLen - 1)) {
            return (<Typography key={`bcItem-${i}`} color="textPrimary">{pageData.stages[i].titles[selection]}</Typography>)
          } else if (i === 0) {
            return (
              <Link key={`bcItem-${i}`} color="inherit" onClick={this.clearSelections.bind(this, page)}>
                {pageData.stages[i]["titles"][0]}
              </Link>
            )
          } else {
            return (
              <Link key={`bcItem-${i}`} color="inherit" onClick={this.handleSelection.bind(this, page, i)}>
                {pageData.stages[i]["titles"][selection]}
              </Link>
            )
          }
        })}
      </Breadcrumbs>
    );
  }

  renderHomePage() {
    return (
      <div className="data">
        <div className={`homeBG ${this.props.bgToggled ? 'homeBGToggled' : ''}`}>
          <Carousel
            infiniteLoop 
            autoPlay
            showArrows={false}
            showStatus={false}
            showIndicators={false}
            showThumbs={false}
            interval={5000}
            showStatus={false}>
              <div>
                  <img src="/homeBG3.png" />
              </div>
{/*              <div>
                  <img src="/homeBG.jpg" />
              </div>
              <div>
                  <img src="/homeBG4.jpg" />
              </div>
              <div>
                  <img src="/homeBG2.png" />
              </div>*/}
          </Carousel>
          <div className="ctaContainer">
            <div className="ctaInnerContainer">
              {/*<div className="search">
                <Paper>
                  <InputBase
                    onChange={this.updateHQ}
                    placeholder="Search Reports"
                    onKeyDown={this.handleSearchKeyDown}
                    inputProps={{ 'aria-label': 'search' }}
                  />
                  <IconButton href={this._buildLinkHref()} aria-label="search">
                    <SearchIcon />
                  </IconButton>
                </Paper>
              </div>*/}
              <Button className="ctaBtn" href="/post-flight/new" variant="contained" color="primary">Submit Post Flight Data</Button>
            </div>
          </div>
        </div>
      </div>
    );
  }

  render() {
    return (
      <div className="Home">
        {this.props.isAuthenticated ? this.renderHomePage() : this.renderLander()}
      </div>
    );
  }
}
